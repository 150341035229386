import API_BASE_URL from "./apiConfig";

export const databaseTablesName = 'devkbgua_KBC_SOS_Staging_Database'

export const getDatabaseTablesRendering = (table) => {
  return table[`Tables_in_${databaseTablesName}`];
};

export const fetchDatabaseTables = async (setTables) => {

  try {

      const response = await fetch(`${API_BASE_URL}/forms`);
      const data = await response.json();
      console.log(`Successfully fetched tables from ${API_BASE_URL}`, data);
      setTables(data);

  } catch (error) {

      console.error(`Error fetching tables from ${API_BASE_URL}`, error);

  }
};

export const handleTableClick = async (tableName, setSelectedTable, setColumns, setRows) => {

  setSelectedTable(tableName);
  
  try {

      const columnResponse = await fetch(`${API_BASE_URL}/columns/${tableName}`);
      const columnData = await columnResponse.json();

      console.log(`Successfully fetched columns from ${API_BASE_URL}`, columnData);
      setColumns(columnData);

      const rowsResponse = await fetch(`${API_BASE_URL}/rows/${tableName}`);
      const rowsData = await rowsResponse.json();
      console.log(`Successfully fetched rows from ${API_BASE_URL}`, rowsData);
      setRows(rowsData);

  } catch (error) {

      console.error(`Error fetching data from ${API_BASE_URL}`, error);
      
  }
};

/*-------------------------------------------------------------------
                            Radio Button 
  ------------------------------------------------------------------*/

  export const fetchRiskRatingOptions = async () => {

    try {

      const response = await fetch(`${API_BASE_URL}/risk_rating_options`);
      const data = await response.json();
      console.log(`Successfully fetched risk rating options from ${API_BASE_URL}`, data);
      return data;

    } catch (error) {

      console.error(`Error fetching risk rating options from ${API_BASE_URL}`, error);
      return [];
      
    }

  };

  export const fetchCategoryOptions = async () => {

    try {

      const response = await fetch(`${API_BASE_URL}/category_options`);
      const data = await response.json();
      console.log(`Successfully fetched Category options from ${API_BASE_URL}`, data);
      return data;

    } catch (error) {

      console.error(`Error fetching Category options from ${API_BASE_URL}`, error);
      return [];
      
    }

  };

  export const fetchObservationOptions = async () => {

    try {

      const response = await fetch(`${API_BASE_URL}/observation_outcome_options`);
      const data = await response.json();
      console.log(`Successfully fetched observation outcome options from ${API_BASE_URL}`, data);
      return data;

    } catch (error) {

      console.error(`Error fetching observation outcome options from ${API_BASE_URL}`, error);
      return [];
      
    }

  };