/* SHE File Items components.
    
    -   This component renders all the available SHE files, when user clicks on a SHE file they will be able to add, remove 
        or edit the SHE Files Items. 

*/

// React Components 

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

// API Base URL 

import API_BASE_URL from "../utils/apiConfig"; // this imports the URL thats being used

// CSS File - SheFileStyles 

import '../styles/shefilestyles.css';

// Functions - Render tables 

import { filterTables } from "../../../utils/RenderingFilterTables"; // this renders the tables according to the database its connected to. 
import { fetchDatabaseTables, handleTableClick } from "../utils/frontendTables";
import { getDatabaseTablesRendering , fetchRiskRatingOptions, fetchCategoryOptions, fetchObservationOptions} from "../utils/frontendTables";

// Application components 

import EditSHEFileItems from "./EditSHEFileItems"; // component to add SHE File items


// Icons 

import { TfiWrite } from "react-icons/tfi"; // Pen and paper icon 

// Toaster Components

import FilledInChecklistToaster from "../../toasterscomponents/FilledInChecklistToaster";

const SheFileItemsComponent = () => {

    // Retrieve user data from session storage
    const user = JSON.parse(sessionStorage.getItem('user'));

    // -------------- State Management ------------------

    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState(null);
    const [columns, setColumns] = useState([]);
    const [formData, setFormData] = useState({});
    const [showFillInChecklist, setShowFillInChecklist] = useState(false);
    const [uniqueID, setUniqueID] = useState('');
    const [rows, setRows] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [formName, setFormName] = useState('');
    const [formSections, setFormSections] = useState([]);

    //radio button 
    const [riskRatingOptions, setRiskRatingOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [observationOutcomeOptions, setObservationOptions] = useState([]);

    //
    const [formattedDateTime, setFormattedDateTime] = useState("")

    // error handling state
    const [showTextEmptyNameError, setShowTextEmptyNameError] = useState(false);

    // -------------- Component FUnctions -----------------

    // Filter out the 'manageuserscreatecompany' table
    const filteredTables = filterTables(tables, table => getDatabaseTablesRendering(table));

    const navigate = useNavigate();

    //
    const handleInputChange = (columnName, value) => {
        if (columnName === 'id') {
            setFormData({ ...formData, id: value });
        } else {
            setFormData({ ...formData, [columnName]: value });
        }
    };

    const handleClear = () => {
        setFormData({});
        setFormSections([]);
    };

    const clearFormAndName = () => {
        setFormName('');
        setFormSections([]);
    };

    const generateUniqueID = () => {
        const currentDate = new Date();
        const year = String(currentDate.getFullYear()).slice(-2);
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const randomDigits = String(Math.floor(1000 + Math.random() * 9000));

        return `${year}${month}${day}${seconds}${randomDigits}`.slice(0, 11);
    };

    
    // Navigate back
    const handleNavigateBack = () => {

        navigate('/checklistmanagement')
        
      
      }

      // Modify the validateFormData function
    const validateFormData = (data) => {
    for (let column of columns) {
        if (column !== 'id' && column !== 'userId' && column !== 'date_submitted' && !data[column]) {
            return false;
        }
    }
    return true;
};

//
const formatDateTime = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day} ${month} ${year} ${hours}:${minutes}`;
};

const handleClick = (tableName) => {
    handleTableClick(tableName, setSelectedTable, setColumns, setRows);
    setShowFillInChecklist(true);
    setUniqueID(generateUniqueID());
};

const handleBackClick = () => {
    setShowFillInChecklist(false);
    handleClear();
    setShowTextEmptyNameError(false)
};

const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submitting form...');

    // Include userId in formData
    const formDataWithUserId = { ...formData, userId: user.id };

    // Log the formData before validation
    console.log('Form data input by user:', formDataWithUserId);

    if (!validateFormData(formDataWithUserId)) {
        console.log('Form validation failed');
        setShowTextEmptyNameError(true);
        return;
    }

    // Log the formData before validation
    console.log('Form data input by user:', formData);

    
    try {
        console.log('Form validation passed');
        const uniqueID = generateUniqueID();
        console.log('Generated unique ID:', uniqueID);

        const formSections = await Promise.all(Object.entries(formData).map(async ([name, value]) => {
            if (value instanceof File) {
                console.log(`Converting file to base64 for field: ${name}`);
                const base64File = await convertFileToBase64(value);
                return { name, question: base64File };
            } else {
                console.log(`Processing field: ${name} with value: ${value}`);
                return { name, question: value };
            }
        }));

        console.log('Form sections prepared:', formSections);

        const response = await axios.post(`${API_BASE_URL}/submit_form`, {
            uniqueID: uniqueID,
            formName: selectedTable,
            formSections: formSections,
            userId: user.id , // to include the user ID
            date_submitted: formattedDateTime
            
        });

        console.log('Form data submitted successfully:', response.data);
        setShowSuccess(true);

        setTimeout(() => {
            console.log('Navigating to /viewchecklist');
            setShowSuccess(false);
            navigate('/viewchecklist');
        }, 2000);

        setShowTextEmptyNameError(false);

        console.log('Clearing form and name');
        clearFormAndName();
        handleClear();

    } catch (error) {
        console.error('Error submitting form data:', error);

    }
};
    

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };


// ----------------- Use Effect ------------

useEffect(() => {
    fetchDatabaseTables(setTables);
    fetchRiskRatingOptions().then(setRiskRatingOptions); 
    fetchCategoryOptions().then(setCategoryOptions);
    fetchObservationOptions().then(setObservationOptions);
    
  }, []);


useEffect(() => {
    const updateDateTime = () => {
        setFormattedDateTime(formatDateTime(new Date()));
    };

    // Update the date and time immediately when the component mounts
    updateDateTime();

    // Set an interval to update the date and time every minute
    const interval = setInterval(updateDateTime, 60000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
}, []);
    





    useEffect(() => {
        fetchDatabaseTables(setTables);
    }, []);

    
    

    return (
        <div className="component-container">
            
            <div className="view-checklist-component-container">
                
                <div className="fill-in-checklist-component-container">

                    <div>
                        <h1>SHE file items</h1>
                    </div>

                    <div>
                        <p>Add SHE file items to availble SHE files</p>
                    </div>
                    {/* Navigate back */}
                    <div className="navigate-back-button-main-container">

                        <div className="navigate-back-button-container">
                            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                        </div>

                    </div>
                </div>

                <div className="view-fillin--checklist-table-container">
                    <div className="view-checklist-table-table-container">
                        <table className="view-fillin-checklist-table">
                            <thead>
                                <tr>
                                    <th className="table-header">Fill In Checklist</th>
                                    <th className="table-header">Checklist Name</th>
                                </tr>
                            </thead>
                            <tbody className="view-table-body">
                                {filteredTables.length === 0 ? (
                                    <tr>
                                        <td colSpan="4">
                                            <p>
                                                Currently no available checklists, click "Create Checklist' to create a checklist.
                                            </p>
                                            <Link to='/createchecklist'>
                                                <div className='view-checklist-create-checklist-button-contianer'>
                                                    <p>Create Checklist</p>
                                                </div>
                                            </Link>
                                        </td>
                                    </tr>
                                ) : (
                                    filteredTables.map((table, index) => (
                                        <tr key={index}>
                                            <td>
                                                <TfiWrite className="fillin-checklist-submittion-icon" onClick={() => handleClick(getDatabaseTablesRendering(table))} />
                                            </td>
                                            <td>{getDatabaseTablesRendering(table)}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div>

                {selectedTable && showFillInChecklist && (
                    
                    <EditSHEFileItems

                        selectedTable={selectedTable}
                        columns={columns}
                        formData={formData}
                        setFormData={setFormData}
                        handleInputChange={handleInputChange}
                        handleSubmit={handleSubmit}
                        handleBackClick={handleBackClick}
                        uniqueID={uniqueID}
                        riskRatingOptions={riskRatingOptions}
                        categoryOptions={categoryOptions}
                        observationOutcomeOptions={observationOutcomeOptions}
                        showTextEmptyNameError={showTextEmptyNameError}
                        
                    />

                )}

            </div>

            {showSuccess && 

                <FilledInChecklistToaster/>
                
            }

            
        </div>
    );
};

export default SheFileItemsComponent;
