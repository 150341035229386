import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserInfoHeader from '../../UserInfoHeader';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import API_BASE_URL from '../utils/apiConfig';

import {filterTables} from '../../../utils/RenderingFilterTables'

import ConfirmdeleteTableToaster from '../../toasterscomponents/checklistToasters/ConfirmdeleteTableToaster';

// CSS
import '../../../assets/css/Checklists.css';

// Icons
import { FaRegEye } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { LuUsers } from 'react-icons/lu';
import { MdDeleteOutline } from "react-icons/md";

import { CiImageOn } from "react-icons/ci";

// Utils
import { fetchDatabaseTables, handleTableClick , getDatabaseTablesRendering } from '../utils/frontendTables';

const ViewChecklist = () => {

  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  //
  const [showDeleteToaster, setShowDeleteToaster] = useState(false);
  const [tableToDelete, setTableToDelete] = useState(null);
  const [expandedImageIndex, setExpandedImageIndex] = useState(null);

  //
  const [deletionCount, setDeletionCount] = useState(0); // New state variable to track 

  const navigate = useNavigate();

  useEffect(() => {
    fetchDatabaseTables(setTables);
  }, [deletionCount]); // Update the checklist table when deletionCount changes

  const handleClick = (tableName) => {

    handleTableClick(tableName, setSelectedTable, setColumns, setRows);
    
  };

  const navigateToManageUsers = () => {
    navigate('/assignusers');
  };

  const handleImageClick = (index) => {
    setExpandedImageIndex(expandedImageIndex === index ? null : index);
  };

  const handleDeleteClick = (tableName) => {
    setTableToDelete(tableName);
    setShowDeleteToaster(true);
  };

  const handleConfirmDelete = async () => {
    
    if (tableToDelete) {
      try {
        console.log(`Attempting to delete table: ${tableToDelete}`);
        const response = await axios.delete(`${API_BASE_URL}/delete_table/${tableToDelete}`);
        console.log(`Delete response:`, response.data);
        fetchDatabaseTables(setTables);
        setSelectedTable(null); // Close the selected table view
        console.log(`Table ${tableToDelete} deleted and archived successfully. Table list refreshed.`);
      } catch (error) {
        console.error('Error deleting and archiving table:', error);
      }
    }
    setShowDeleteToaster(false);
    setTableToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteToaster(false);
    setTableToDelete(null);
  };

  // Navigate back
  const handleNavigateBack = () => {

    navigate('/checklistmanagement')
    
  
  }

  // Filter out the 'manageuserscreatecompany' table
  const filteredTables = filterTables(tables, table => getDatabaseTablesRendering(table));

  return (

    <div className="component-container">

      <div className="create-checklist-component-container">
        <div className="create-checklist-text-container">
          <h1> View & Manage CheckLists </h1>
          <p> View, edit, and delete checklists. </p>
        </div>

        {/* Navigate back */}
        <div className="navigate-back-button-main-container">
  
            <div className="navigate-back-button-container">
              <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
            </div>

        </div>

        <div className="view-checklist-table-container">
          <div className="view-checklist-table-table-container">
            <table className="view-checklist-table">
              <thead>
                <tr>
                  <th className="table-header">View Checklist submissions</th>
                  <th className="table-header">Checklist Name</th>
                  <th className="table-header">Assign Users</th>
                  <th className="table-header">Delete</th>
                </tr>
              </thead>
              <tbody className="view-table-body">
                {filteredTables.length === 0 ? (
                  <tr>
                    <td colSpan="4">
                      <p>
                        Currently no available checklists, click "Create Checklist' to create a checklist.
                      </p>
                      <Link to='/createchecklist'>
                        <div className='view-checklist-create-checklist-button-contianer '>
                          <p>Create Checklist</p>
                        </div>
                      </Link>
                    </td>
                  </tr>
                ) : (
                  filteredTables.map((table, index) => (
                    <tr key={index}>
                      <td onClick={() => handleClick(getDatabaseTablesRendering(table))}>
                        <FaRegEye className='view-checklist-submition-icon' />
                      </td>
                      <td>{getDatabaseTablesRendering(table)}</td>
                      <td onClick={() => navigateToManageUsers()}>
                        <LuUsers className='manage-users-checklist-submition-icon' />
                      </td>
                      <td onClick={() => handleDeleteClick(getDatabaseTablesRendering(table))}>
                        <MdDeleteOutline className='delete-checklist-submition-icon' />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {selectedTable && (

          <div className='view-checklist-checklist-data-container'>

          <div className="view-data-checklist-table-container">
            <div>
              <h1>Checklist Data</h1>
            </div>
            <div>
              <h3>Checklist Name: {selectedTable}</h3>
            </div>

            <div>
              <p>View checklist submission for {selectedTable}</p>
            </div>

            <div className='view-checklist-submission-table-container'>

            <table className="view-data-checklist-table">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th className="table-header" key={index}>
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="view-table-body">
                {rows.map((row, index) => (
                  <tr key={index}>
                    {columns.map((column, columnIndex) => (
                      <td key={columnIndex}>
                        {typeof row[column] === 'string' && column.toLowerCase() === 'photo' ? (
                          <div onClick={() => handleImageClick(index)}>
                            {expandedImageIndex === index ? (
                              <img 
                                className='view-checklist-view-image' 
                                src={atob(row[column])} // Decode the base64 string
                                alt='view' 
                              />
                            ) : (
                              <CiImageOn className='view-checklist-view-image-icon'/>
                            )}
                          </div>
                        ) : (
                          row[column]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>


            </div>

            
          </div>

          </div>
          
        )}
      </div>

      {showDeleteToaster && (

        <ConfirmdeleteTableToaster
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
        
      )}
    </div>
  );
};

export default ViewChecklist;
