// utils/tableUtils.js

// Display none for the following tables that dont need to be rendered onot the front end

// utils/tableUtils.js

// utils/tableUtils.js

export const filterTables = (tables, getDatabaseTablesRendering) => {
    return tables.filter(table => {
        const rendering = getDatabaseTablesRendering(table);
        
        // Check if rendering is undefined or null
        if (!rendering) {
            return false;
        }
        
        // Include tables with "deleted_" prefix
        if (rendering.startsWith('deleted_')) {
            return false;
        }
        
        // Exclude specific tables
        const excludedTables = [
            'app_checklists',
            'app_checklists_user',
            'app_checklist_radio_category_options',
            'app_checklist_radio_observation_outcome_options',
            'app_checklist_radio_risk_rating_options',
            'app_users_createcompany',
            'app_users_createuser',
            'app_users_department',
        ];
        
        return !excludedTables.includes(rendering);
    });
};