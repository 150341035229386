import React from "react";

import { CgFileRemove } from "react-icons/cg";

// CSS File 
import '../../../assets/css/Toasters.css';

const RemovedChecklistToaster = () => {

    return (

        <div className="added-checklist-section-component-container">

            <div className="added-checklist-section-container">

                <div className="added-checklist-section-tick-container">

                    <CgFileRemove className="remove-checklist-section-tick" />
                    
                </div>

                <div className="added-checklist-section-text-container">

                    <div>
                        <p> Removed Checklist field  </p>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default RemovedChecklistToaster