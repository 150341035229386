import React from "react";

import { LiaBookSolid } from "react-icons/lia";
// CSS File 
import '../../assets/css/construction.css';

const HelpComponents = () => {

    return (
        <div className="component-container"> 

            

            <div className="construction-component-container">

            <div>
            <h2>Coming soon - KBC SPC Application Resource Center. </h2>
            </div>

            <div>
                <LiaBookSolid className="construction-logo" />
            </div>

            <div>
                
                    <br></br> 
                    <p>
                        You will find a comprehensive guide on how to effectively use our KBC SPC application. This section includes detailed documentation and valuable resources to assist you in navigating and utilizing the features of our application.
                        Should you require further assistance or have any questions, please do not hesitate to contact us at info@kbcsafety.com.
                    </p>
            </div>
            
            <div>
                <h4>KBC Development Team</h4>
            </div>

            </div>

            
            
        
        </div>
    )
}

export default HelpComponents