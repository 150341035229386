import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import UserInfoHeader from "../../UserInfoHeader";

import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

import { fetchDatabaseTables } from "../../checklistmanagementcomponents/utils/frontendTables";

import { filterTables } from "../../../utils/RenderingFilterTables";

import { MdAssignmentAdd } from "react-icons/md";

import ToastError from "../../toasterscomponents/ToastError";

// component- toaster 
import CreatedSuccessfullToaster from "../../toasterscomponents/manageusersToaster/createdSuccessfullToaster";


const AssignUserComponent = () => {
  
  const [selectedChecklist, setSelectedChecklist] = useState('');
  const [selectedChecklists, setSelectedChecklists] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [tables, setTables] = useState([]);

  //toaster
  const [companyCreatedSuccessfullyToaster, setCompanyCreatedSuccessfullyToaster] = useState(false);

  const [showTextEmptyNameError, setShowTextEmptyNameError] = useState(false);
  const [showAssignUserError, setShowAssignUserError] = useState(false);

  //
  const navigate = useNavigate();

  const filteredDepartments = departments.filter(department => department.companyId === selectedCompany);

  // Filter out the 'manageuserscreatecompany' table
  const filteredTables = filterTables(tables, table => table.Tables_in_devkbgua_KBCSPC_Databasev1);

  //
  const clearForm = () => {

    setSelectedDepartment('')
    setSelectedUser('')
    setSelectedCompany('')
    setSelectedChecklist('')
    setSelectedChecklists('')
    setShowTextEmptyNameError(false)
    
}

  useEffect(() => {
    fetchDatabaseTables(setTables);
    console.log('Fetched tables:', tables);  // Add this line

    fetch('/api/companies')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          console.log('Fetched companies:', data);
          setCompanies(data);
        } else {
          console.error('Invalid data format for companies:', data);
        }
      })
      .catch(error => console.error('Error fetching companies:', error));

    fetch('/api/departments')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          console.log('Fetched departments:', data);
          setDepartments(data);
        } else {
          console.error('Invalid data format for departments:', data);
        }
      })
      .catch(error => console.error('Error fetching departments:', error));
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      fetch(`/api/users/${selectedDepartment}`)
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data)) {
            console.log('Fetched users for department:', data);
            setUsers(data);
          } else {
            console.error('Invalid data format for users:', data);
          }
        })
        .catch(error => console.error('Error fetching users for department:', error));
    } else {
      setUsers([]);
    }
  }, [selectedDepartment]);

  //
  // Function to generate unique ID
  const generateUniqueId = () => {

    const assign = 'assign'
    const year = new Date().getFullYear().toString().slice(-2); // Last two digits of the year
    const month = ('0' + (new Date().getMonth() + 1)).slice(-2); // Month (zero padded)
    const day = ('0' + new Date().getDate()).slice(-2); // Day (zero padded)
    const time = ('0' + new Date().getHours()); // Day (zero padded)
    const timeMinute = ('0' + new Date().getMinutes()); // Day (zero padded)
    const randomDigits = Math.floor(10 + Math.random() * 9000); // Random 3-digit number

    return `${assign}${year}${month}${day}${time}${timeMinute}${randomDigits}`;
}

const handleChecklistChange = (e) => {
  const value = e.target.value;
  const isChecked = e.target.checked;

  setSelectedChecklists(prev => 
    isChecked 
      ? [...prev, value]
      : prev.filter(item => item !== value)
  );
};

// Navigate back
const handleNavigateBack = () => {

  navigate('/managerusers')

}

// ... in handleAssignUser function ...
const handleAssignUser = async (e) => {

  e.preventDefault();

  if (!selectedCompany || !selectedDepartment || !selectedUser || selectedChecklists.length === 0) {
    setShowTextEmptyNameError(true);
    setShowAssignUserError(true);

    // Automatically hide the error toaster after 3 seconds
    setTimeout(() => {
      setShowAssignUserError(false);
    }, 1000);
    
    return;
  }

  setShowTextEmptyNameError(false);

  

  try {
    const checklistIds = await Promise.all(selectedChecklists.map(async (checklistName) => {
      const response = await fetch(`/api/get_checklist_id/${checklistName}`);
      const data = await response.json();
      return data.id;
    }));

    console.log('Sending request to assign user to checklists:', {
      userId: selectedUser,
      checklistIds: checklistIds,
    });

    const response = await fetch('/api/assign_user_to_checklists', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: selectedUser,
        checklistIds: checklistIds,
      }),
    });

    if (response.ok) {
      console.log('User assigned to checklists successfully.');
      clearForm();

      //
      setCompanyCreatedSuccessfullyToaster(true);

      // Automatically hide the SuccessChecklistCreated component after 3 seconds
      setTimeout(() => {
                
        setCompanyCreatedSuccessfullyToaster(false);
        navigate('/viewusers');

    }, 2000);

    } else {
      console.error('Failed to assign user to checklists');
    }
  } catch (error) {
    console.error('Error assigning user to checklists:', error);
  }
};


  return (

    <div className="component-container">

      <div className="checklist-component-container">

        <div className="create-company-text-container">

          <div>

            <h1>Assign Users</h1>
            <p>To assign a checklist to a user.</p>
            
          </div>

          <div>
            <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
          </div>

        </div>

        {/* Navigate back */}
        <div className="navigate-back-button-main-container">

                    <div className="navigate-back-button-container">
                        <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                    </div>

        </div>

        <div className="view-checklist-table-container">
          <div className="view-checklist-table-table-container">
            <table className="view-checklist-table">
              <thead>
                <tr>
                  <th className="table-header">Checklist Name</th>
                  <th className="table-header">Company</th>
                  <th className="table-header">Department</th>
                  <th className="table-header">Users</th>
                  <th className="table-header">Assign</th>
                </tr>
              </thead>
              <tbody className="view-table-body">


                <tr>

                  {/* Checkbox */}
                  <td>
                      <div className="assign-user-checkbox-container">
                      {filteredTables.length === 0 ? (
  <p>Currently no checklists available</p>
) : (
  filteredTables.map(table => (
    <div key={table.Tables_in_devkbgua_KBCSPC_Databasev1} className="assign-user-checkbox-item">
      <input
        className="assign-user-checkbox"
        type="checkbox"
        id={table.Tables_in_devkbgua_KBCSPC_Databasev1}
        value={table.Tables_in_devkbgua_KBCSPC_Databasev1}
        checked={selectedChecklists.includes(table.Tables_in_devkbgua_KBCSPC_Databasev1)}
        onChange={handleChecklistChange}
      />
      <label htmlFor={table.Tables_in_devkbgua_KBCSPC_Databasev1}>
        {table.Tables_in_devkbgua_KBCSPC_Databasev1}
      </label>
    </div>
  ))
)}
                      </div>
                    </td>
                  <td>
                    <select
                      value={selectedCompany}
                      onChange={e => setSelectedCompany(e.target.value)}
                    >
                      <option value="">-- Select Company --</option>
                      {companies.map(company => (
                        <option key={company.id} value={company.id}>
                          {company.companyName}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={selectedDepartment}
                      onChange={e => setSelectedDepartment(e.target.value)}
                    >
                      <option value="">-- Select Department --</option>
                      {filteredDepartments.length > 0 && (
  filteredDepartments.map(department => (
    <option key={department.id} value={department.id}>
      {department.departmentName}
    </option>
  ))
)}
                    </select>
                  </td>
                  <td>
                    <select
                      value={selectedUser}
                      onChange={e => setSelectedUser(e.target.value)}
                    >
                      <option value="">-- Select User --</option>
                      {users.map(user => (
                        <option key={user.id} value={user.id}>
                          {user.userName} ({user.id})
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <MdAssignmentAdd onClick={handleAssignUser} className="assign-user-assign-icon" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {showTextEmptyNameError && (
            <p className="error-text">Error! Empty fields, please fill in all fields and select at least one checklist.</p>
          )}

          <div className="assign-user-button-container-container">

            <div className="assign-user-button-container">
              <button className="assign-user-button" type='button' onClick={clearForm}>Reset </button>
            </div>
            
          </div>

        </div>
      </div>

      {showAssignUserError && 
        <ToastError 
          toasterError='Error Assigning User'
        />
      }

      {companyCreatedSuccessfullyToaster && 

        <CreatedSuccessfullToaster 
            successToasterTitle="Assigned Successfully" 
            successToasterMessage="User assigned to checklist successfully. /n One moment while we redirect you to view users page"
        />

      }
    </div>
  );
};

export default AssignUserComponent;
