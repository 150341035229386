import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

//icons
import { FaRegEye } from 'react-icons/fa';
import { MdDeleteOutline } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";

import { AiOutlineUserDelete } from "react-icons/ai";

//toaster 
import ConfirmDeleteToasterComponent from "../../toasterscomponents/deleteToasters/ConfimDeleteToasterComponent";

const ViewUsersComponent = () => {

  const [companies, setCompanies] = useState([]);
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState(null);

  const [showDeleteToaster, setShowDeleteToaster] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);



  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('/rows/app_users_createcompany');
        setCompanies(response.data);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompanyUsers) {
      console.log('Selected company users:', selectedCompanyUsers);
    }
  }, [selectedCompanyUsers]);

  const fetchUsersForCompany = async (companyId) => {
    try {
      const response = await axios.get(`/api/users/company/${companyId}`);
      console.log(`Fetched users for company ${companyId}:`, response.data);
      setSelectedCompanyUsers(response.data);
    } catch (error) {
      console.error('Error fetching users for company:', error);
    }
  };

  const handleNavigateBack = () => {
    navigate('/managerusers');
  };

  const handleDeleteCompany = (companyId) => {
    setCompanyToDelete(companyId);
    setShowDeleteToaster(true);
  };

  
  
  const handleCancelDelete = () => {
    setShowDeleteToaster(false);
    setCompanyToDelete(null);
  };

  //
  const handleUnassignChecklist = async (userId, checklistName) => {
    try {
      const response = await axios.post('/api/unassign_user_from_checklist', { userId, checklistName });
      console.log('Unassign response:', response.data);
      if (response.status === 200) {
        // Refresh the user data after unassigning
        await fetchUsersForCompany(selectedCompanyUsers[0].companyId);
      } else {
        console.error('Error unassigning user from checklist:', response.data.error);
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error('Error unassigning user from checklist:', error.response ? error.response.data.error : error.message);
      // You might want to show an error message to the user here
    }
  };
  
  //
  const handleConfirmDelete = async () => {
    
    try {
      await axios.post(`/api/archive-company/${companyToDelete}`);
      setCompanies(companies.filter(company => company.id !== companyToDelete));
      setShowDeleteToaster(false);
      setCompanyToDelete(null);
    } catch (error) {
      console.error('Error archiving company:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <div className="component-container">
      <div className="create-checklist-component-container">
        
        <div className="create-company-text-container">
          <div>
            <h1>View Users</h1>
            <p>View, edit, and delete users.</p>
          </div>
          <div>
            <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
          </div>
        </div>

        <div className="navigate-back-button-main-container">
          <div className="navigate-back-button-container">
            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
          </div>
        </div>

        <div className="view-checklist-table-container">

          <div className="view-checklist-table-table-container">

            <div>
              <h2>Companies</h2>
            </div>

            <div className="view-checklist-checklist-table-container"> 

            <table className="view-checklist-table">
              <thead>
                <tr>
                  <th className="table-header">Company ID</th>
                  <th className="table-header">Company Name</th>
                  <th className="table-header">View Users</th>
                  <th className="table-header">Edit Company</th>
                </tr>
              </thead>
              <tbody className="view-table-body">
                {companies.length === 0 ? (
                  <tr>
                    <td colSpan="4">
                      <p>No companies created yet.</p>
                    </td>
                  </tr>
                ) : (
                  companies.map((company, index) => (
                    <tr key={index}>
                      <td>{company.id}</td>
                      <td>{company.companyName}</td>
                      <td>
                        <FaRegEye 
                          className='view-checklist-submition-icon' 
                          onClick={() => fetchUsersForCompany(company.id)}
                        />
                      </td>
                      <td>
                        <MdDeleteOutline 
                          className='delete-checklist-submition-icon'
                          onClick={() => handleDeleteCompany(company.id)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            </div>

            
          </div>
        </div>

        {selectedCompanyUsers && (
        <div className="view-users-container">

          <div>
            <h3>Users for Selected Company</h3>
          </div>

          <div>
          <table className="view-selected-user-table">
              <thead>
                <tr>
                  <th className="table-header">id</th>
                  <th className="table-header">Name</th>
                  <th className="table-header">Surname</th>
                  <th className="table-header">ID Number</th>
                  <th className="table-header">Company Name</th>
                  <th className="table-header">User Email</th>
                  <th className="table-header">Assigned Checklists</th>
                  <th className="table-header">Edit User</th>
                </tr>
              </thead>

              <tbody>
                {selectedCompanyUsers.map((user, index) => (
                  <tr key={index}>
                    <td className="view-user-td">{user.id}</td>
                    <td className="view-user-td">{user.userName}</td>
                    <td className="view-user-td">{user.userSurname}</td>
                    <td className="view-user-td">{user.userIdNo}</td>
                    <td className="view-user-td">{user.companyName}</td>
                    <td className="view-user-td">{user.userEmail}</td>

                    <td className="view-user-td">
                    {user.assignedChecklists ? (
    <div className="view-user-assigned-checklist-container">
      <ul className="view-user-assigned-list-container">
        {user.assignedChecklists.split(', ').map((checklist, index) => (
          <li className="view-user-assigned-list-container" key={index}>
            <div className="view-user-assigned-list">
              <div>{checklist}</div>
              <div >
                <AiOutlineUserDelete className='delete-checklist-submition-icon' onClick={() => handleUnassignChecklist(user.id, checklist)}/>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    'User not assigned to checklists'
  )}
                    </td>

                    <td className="view-user-td"><FaUserEdit  className='delete-checklist-submition-icon' /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
            
          </div>
        )}
      </div>

      {showDeleteToaster && (

        <ConfirmDeleteToasterComponent

          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          confirmDeleteHeading = 'Company'
          deleteUserAndCompanyWarning='Warning! Deleting a Company will delete all users assocaited to the company'

        />

      )}

    </div>
  );
};

export default ViewUsersComponent;
