import React from "react";
import { useState } from "react";
import axios from "axios";

// CSS File - Checklists
import '../../assets/css/Checklists.css';

//icons - buttons add section
import { IoCalendarOutline } from "react-icons/io5"; // form field icon - add date (calender)
import { MdDriveFileRenameOutline } from "react-icons/md"; // form field icon - add name (pen)
import { IoLocationOutline } from "react-icons/io5"; // form field icon - add location (pin)
import { MdOutlineAddPhotoAlternate } from "react-icons/md"; // form field icon - add photo (photo plus)
import { CiTextAlignCenter } from "react-icons/ci"; // form field icon - add free text (paragraph)
import { MdNumbers } from "react-icons/md"; // form field icon - add number (number)
import { IoPhonePortraitOutline } from "react-icons/io5"; // form field icon - add phone number (phone)
import { RiListRadio } from "react-icons/ri";// form icon field - add radio button (radio list)

import { CiCircleInfo } from "react-icons/ci";

//icons - buttons submenu 
import { RxDropdownMenu } from "react-icons/rx";
import { CiBoxList } from "react-icons/ci";

const FormFieldsButton = ({ addFormSection }) => {

    const [customFieldName, setCustomFieldName] = useState(''); //
    const [customFileFieldName, setCustomFileFieldName] = useState(''); //


    const [customRadioName, setCustomRadioName] = useState(''); // Add state to manage the input field value for custom radio button name
    const [options, setOptions] = useState([]);

    const [predefinedFormVisible, setPredefinedFormVisible] = useState(false); //
    const [listFormVisible, setListFormVisible] = useState(false); //
    const [listFreeTextVisible, setFreeTextVisible] = useState(false); // Custom Free text


    const [listCustomFieldVisible, setCustomFieldVisible] = useState(false); // Custom Custom fields


    const [listRadioButtonVisible, setListRadioButtonVisible] = useState(false) //

    // Visibilities for when user clicks on headin, theres a drop down menu that shows and hides buttons
    const togglePredefinedFormVisibility = () => {

        setPredefinedFormVisible(!predefinedFormVisible);
        
    };

    // free text
    const toggleFreeTextVisibility = () => {

        setFreeTextVisible(!listFreeTextVisible);
        
    };

    // List 
    const toggleListFormVisibility = () => {

        setListFormVisible(!listFormVisible);
        
    };

    //
    // List 
    const toggleCustomFileVisibility = () => {


        setCustomFieldVisible(!listCustomFieldVisible)
        
    };

    const toggleRadioButtonVisibility = () => {

        setListRadioButtonVisible(!listRadioButtonVisible)
    }

    //
    const handleCustomFieldChange = (e) => {

        setCustomFieldName(e.target.value);
    
    };

    const handleCustomFileFieldChange = (e) => {

        setCustomFileFieldName(e.target.value)

    };

    const addCustomField = () => {
        
        if (customFieldName.trim() !== '') {
            // Assuming it's a text input field
            const newCustomField = {
                name: customFieldName,
                inputField: "customText"
            };
            
            addFormSection(newCustomField);
            setCustomFieldName('');
        }
    }

    const addCustomFileField = () => {
        
        if (customFieldName.trim() !== '') {
            // Assuming it's a text input field
            const newCustomField = {
                name: customFieldName,
                inputField: "customFileFieldInput"
            };
            
            addFormSection(newCustomField);
            setCustomFieldName('');
        }
    }

    // Radio Button
    // Function to handle change in custom radio button name input field
    const handleCustomRadioNameChange = (e) => {
    setCustomRadioName(e.target.value);
}

const addCustomRadioButton = () => {

    if (customRadioName.trim() !== '') {

        const newCustomRadioButton = {
            name: customRadioName,
            inputField: "radio",
            options: options
        };
        addFormSection(newCustomRadioButton); // Add the new radio button list field to the form sections array
        setCustomRadioName(''); // Clear the custom radio button name input field
        setOptions([]); // Clear the options array
    }
};


    
    return (
        
        
       <div className="checklist-form-fields-conatiner"> 
            
            <div className="form-fields-text-container">
                
                <p> To add a checklist section click on the button below  </p>

            </div>

            {/* ---------------------------------------------
                                form fields List 
                --------------------------------------------- */}

            {/* Sub heading for Predefined formed sections */}
            <div className="form-fields-text-container">

                {/* Subememuu button - predefined forms */}
                <div className="predefined-form-section-button-container">

                    
                    <button type='button' className="predefined-form-section-button-predefined" onClick={togglePredefinedFormVisibility}>

                        <div>
                            <p>Predefined Sections</p>
                        </div>

                        <div>
                            <RxDropdownMenu  className="predefined-form-section-icon"/>
                        </div>

                    
                    </button>

                </div>

                {predefinedFormVisible && (

                <div className="add-form-field-section-container">

                    {/* Add Name Button - container */}
                    <div className="buttons-containers">
           
                        {/* Adds Name button*/}
                        <button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "Name", inputField: "name" })}>
                                      
                            <div className="field-button-container">
                                          
                   
                                <div className="section-button-icon-container">

                                    <MdDriveFileRenameOutline className="section-button-icon"/>

                                </div>

                                <div className="section-button-icon-text">
                                              
                                    <p> Add Name</p>

                                </div>

                            </div>
                                      
                        </button>
              
                    </div>

                    {/* Add Date Button - container */}
                    <div className="buttons-containers">
           
                        {/* Adds Date button*/}
                        <button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "Date", inputField: "date" })}>
                                           
                            <div className="field-button-container">
                                               
                        
                            <div className="section-button-icon-container">

                                <IoCalendarOutline className="section-button-icon"/>

                            </div>

                            <div className="section-button-icon-text">
                                                   
                                <p>Add Date </p>

                            </div>

                        </div>
                                           
                    </button>
                   
                </div>

                {/* Add Location Button - container */}
                <div className="buttons-containers">
           
                    {/* Adds Address button*/}
                    <button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "Address", inputField: "address" })}>
                                           
                        <div className="field-button-container">
                                               
                        
                            <div className="section-button-icon-container">

                                <IoLocationOutline className="section-button-icon"/>

                            </div>

                            <div className="section-button-icon-text">
                                                   
                                <p>Add Address </p>

                            </div>

                        </div>
                                           
                    </button>
                   
                </div>

                {/* Add Location Button - container */}
                <div  className="buttons-containers">
           
                    {/* Adds Location button*/}
                    <button  type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "Phone", inputField: "phone" })}>
                                           
                        <div className="field-button-container">
                                               
                        
                            <div className="section-button-icon-container">

                                <IoPhonePortraitOutline className="section-button-icon"/>

                            </div>

                            <div className="section-button-icon-text">
                                                   
                                <p>Add Phone </p>

                            </div>

                        </div>
                                           
                    </button>
                   
                </div>

                {/* Add Name Button - container */}
                    <div className="buttons-containers">
           
                        {/* Adds Name button*/}
                        <button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "Photo", inputField: "file" })}>
                                      
                            <div className="field-button-container">
                                          
                   
                                <div className="section-button-icon-container">

                                    <MdOutlineAddPhotoAlternate className="section-button-icon"/>

                                </div>

                                <div className="section-button-icon-text">
                                              
                                    <p> Add Photo</p>

                                </div>

                            </div>
                                      
                        </button>
              
                    </div>

                </div>

                )}

            </div>

            {/*----------------------------------------
                            Predefiend List 
                ---------------------------------------*/}

            {/* Subememu button - predefined forms */}

            <div className="form-fields-text-container">

                <div className="predefined-form-section-button-container">

                    
                    <button type='button' className="predefined-form-section-button-predefined" onClick={toggleRadioButtonVisibility}>

                        <div>
                            <p>Predefined Lists</p>
                        </div>

                        <div>
                            <RxDropdownMenu className="predefined-form-section-icon"/>
                        </div>


                    </button>

                </div>
                
                {listRadioButtonVisible && (

                    
                    <div className="buttons-containers">
           
                        {/*// Radio Button predefined - Risk Rating */}
                        <button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "RiskRating", inputField: "radio" })}>
                                      
                            <div className="field-button-container">
                                          
                   
                                <div className="section-button-icon-container">

                                    <CiBoxList className="section-button-icon"/>

                                </div>

                                <div className="section-button-icon-text">
                                              
                                    <p> Radio - Add Risk Rating </p>

                                </div>

                            </div>
                                      
                        </button>

                        <div className="buttons-containers">
           
                        {/*// Radio Button predefined - Add category */}
                        <button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "Category", inputField: "radio" })}>
                                      
                            <div className="field-button-container">
                                          
                   
                                <div className="section-button-icon-container">

                                    <CiBoxList className="section-button-icon"/>

                                </div>

                                <div className="section-button-icon-text">
                                              
                                    <p> Radio - Add Category </p>

                                </div>

                            </div>
                                      
                        </button>
                        
                    </div>

                    <div className="buttons-containers">
           
                        {/*// Radio Button predefined - Add category */}
                        <button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "ObservationOutcome", inputField: "radio" })}>
                                      
                            <div className="field-button-container">
                                          
                   
                                <div className="section-button-icon-container">

                                    <CiBoxList className="section-button-icon"/>

                                </div>

                                <div className="section-button-icon-text">
                                              
                                    <p> Radio - Observation Outcome </p>

                                </div>

                            </div>
                                      
                        </button>
                        
                    </div>

                    </div>

                    

            

                )}

            </div>

            {/* --------------------------------------------
                        Form field - lists 
            ------------------------------------------------*/}

<div className="form-fields-text-container">

{/* Subemenu buttons - lists */}
<div className="predefined-form-section-button-container">

    <button type='button' className="predefined-form-section-button" onClick={toggleFreeTextVisibility}>

        <div>
            <p> Custom Free Text Section </p>
        </div>

        <div>
            <RxDropdownMenu className="predefined-form-section-icon"/>
        </div>

    
    </button>

</div>

{listFreeTextVisible && ( 

    <div className="add-form-field-section-container">

        <div className="custom-form-field-container">
            <h3>Free text custom field </h3> 
            <CiCircleInfo title="Note. When you add free text to custom field. Texts andnumbers will accepted"/>
        </div>

        <div>
            <input className="custom-field-input" type="text" value={customFieldName} onChange={handleCustomFieldChange} placeholder="Enter custom field name" />
        </div>

        <div className="custom-form-field-container">
            <p>Click 'add custom field' button to add custom field section to checklist</p>
        </div>

        <div> 
            <button className="custom-field-button" type='button'onClick={addCustomField}>Add Custom Field</button>
        </div>

    </div>

    )}

{/*<div className="add-form-field-section-container"> 


<div className="buttons-containers">

<button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "Radio Button", inputField: "radio", options: [] })}>

<div className="field-button-container">

<div className="section-button-icon-container">
<RiListRadio className="section-button-icon"/>
</div>

<div className="section-button-icon-text">
<p>Add Radio Button</p>
</div>
</div>

</button>

</div>

</div> */}

</div>
            {/*-------------------------------------
                         Custom File Fields
                ------------------------------------*/}

                {/* Submenu buttons - Custom File Fields */}
{/*

<div className="form-fields-text-container">

                
                <div className="predefined-form-section-button-container">

                    <button type='button' className="predefined-form-section-button" onClick={toggleCustomFileVisibility}>

                        <div>
                            <p> Custom Files Section </p>
                        </div>

                        <div>
                            <RxDropdownMenu className="predefined-form-section-icon"/>
                        </div>

    
                    </button>

                </div>

{listCustomFieldVisible && ( 

    <div className="add-form-field-section-container">

        <div className="custom-form-field-container">
            <h3> Custom File field </h3> 
            <CiCircleInfo title="Note. When you add free text to custom field. Texts andnumbers will accepted"/>
        </div>

        <div>
            <input className="custom-field-input" type="text" value={customFileFieldName} onChange={handleCustomFileFieldChange} placeholder="Enter custom file field name" />
        </div>

        <div className="custom-form-field-container">
            <p>Click 'add custom file field' button to add custom field section to checklist</p>
        </div>

        <div> 
            <button className="custom-field-button" type='button' onClick={addCustomFileField}>Add Custom File Field</button>
        </div>

    </div>

    )}

{/*<div className="add-form-field-section-container"> 


<div className="buttons-containers">

<button type='button' className="form-section-button-container" onClick={() => addFormSection({ name: "Radio Button", inputField: "radio", options: [] })}>

<div className="field-button-container">

<div className="section-button-icon-container">
<RiListRadio className="section-button-icon"/>
</div>

<div className="section-button-icon-text">
<p>Add Radio Button</p>
</div>
</div>

</button>

</div>

</div> 

</div>
 */}
            

            {/*----------------------------------------
                            List 
                ---------------------------------------*/}

                {/* Subememu button - predefined forms */}

                {/*

                <div className="form-fields-text-container">

                <div className="predefined-form-section-button-container">

                    
                    <button type='button' className="predefined-form-section-button" onClick={toggleRadioButtonVisibility}>

                        <div>
                            <p>Custom Lists</p>
                        </div>

                        <div>
                            <RxDropdownMenu className="predefined-form-section-icon"/>
                        </div>


                    </button>

                </div>
                {/*
                {listRadioButtonVisible && (

                    <div className="add-form-field-section-container">

                        <div className="custom-form-field-container">
                            <h3>Radio Button field </h3> 
                            <CiCircleInfo title="Note. When you add free text to custom field texts andnumbers eill accepted"/>
                        </div>

                        <div>
                            <input className="custom-field-input" type="text" value={customRadioName} onChange={handleCustomRadioNameChange} placeholder="Enter custom radio button field name" />
                        </div>

                        <div className="custom-form-field-container">
                            <p>Click 'add custom radio' button to add custom field section to checklist</p>
                        </div>

                        <div> 
                            <button className="custom-field-button" type='button'onClick={addCustomRadioButton}>Add Radio List Field</button>
                        </div>

                    </div>

                )}
                

{listRadioButtonVisible && (

<div>
    <h4>Custom list Section Maintainence</h4>

    <p>Currently squashing a few bugs. In the meantime, feel free to explore other sections of our APP Thank you for your patience</p>

</div>
)}



</div>
                
                */}

            

            

            

       </div>

        
    )
}


export default FormFieldsButton