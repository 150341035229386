import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import whiteKbcLogo from '../assets/images/branding/white-kbc-logo.png';
import { CiUser, CiLock } from "react-icons/ci";
import { FaLinkedin, FaFacebook, FaSpinner } from "react-icons/fa";

// Toasters
import LoginToaster from "./toasterscomponents/LoginToaster";
import ToastError from "./toasterscomponents/ToastError";

const Login = (props) => {

    const [isMobile, setIsMobile] = useState(false);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [loginStatus, setLoginStatus] = useState('idle');
    const [error, setError] = useState('');
    const [serverError, setServerError] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const validateForm = () => {

        if (!formData.email || !formData.password) {
            setError("Please enter both email and password.");
            return false;
        }

        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setError("Please enter a valid email address.");
            return false;
        }
        return true;
    };

    const handleLogin = async (e) => {

        e.preventDefault();

        setLoginStatus('loading');
        
        if (!validateForm()) {
            setLoginStatus('error');
            return;
        }
    
        
        setError('');
        setServerError(false);
    
        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                setLoginStatus('success');
                setTimeout(() => {
                    props.onAuthentication(true, data.user);
                    navigate('/dashboard');
                }, 1500);
            } else {
                setLoginStatus('error');
                setServerError(response.status === 500);
                if (response.status === 404) {
                    setError("Email doesn't exist. Please check your email or sign up.");
                } else if (response.status === 500) {
                    setError("Server error! Please try again later.");
                } else {
                    setError("Incorrect username or password.");
                }
            }
        } catch (error) {
            console.error('Login error:', error);
            setLoginStatus('error');
            setServerError(true);
            setError("An unexpected error occurred. Please try again.");
        }
    };

    const renderForm = () => (
        <form onSubmit={handleLogin} className={isMobile ? "login-mobile-form" : "login-form"}>
            <div className={`login-${isMobile ? 'mobile-' : ''}form-element-container`}>
                <div className={`login-${isMobile ? 'mobile-' : ''}form-label`}>
                    <label><h3>Username</h3></label>
                </div>
                <div className={`login-${isMobile ? 'mobile-' : ''}form-input-container`}>
                    <CiUser className="login-form-input-icon" />
                    <input 
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder="Enter your email"
                        className={`login-${isMobile ? 'mobile-' : ''}form-input`}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className={`login-${isMobile ? 'mobile-' : ''}form-element-container`}>
                <div className="login-form-label">
                    <label><h3>Password</h3></label>
                </div>
                <div className={`login-${isMobile ? 'mobile-' : ''}form-input-container`}>
                    <CiLock className="login-form-input-icon" />
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        placeholder="Enter your password"
                        className={`login-${isMobile ? 'mobile-' : ''}form-input`}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            {loginStatus === 'error' && (
                <div>
                    <p className="error-text">{error}</p>
                </div>
            )}

            <div className={`login-${isMobile ? 'mobile-' : ''}terms-and-conditions-container`}>
                <p>By clicking 'Login' you agree to the terms and conditions of using KBC SPC App.</p>
            </div>

            <div className={`login-${isMobile ? 'mobile-' : ''}form-button-container`}>
                <button className="login-form-button" type="submit" disabled={loginStatus === 'loading'}>
                    {loginStatus === 'loading' ? <FaSpinner className="fa-spin" /> : 'Login'}
                </button>
            </div>
        </form>
    );

    return (
        <div className="login-component-container">

            {/* SOS Application  */}
            {isMobile ? (
                <div className="login-component-mobile">
                    <div className="login-mobile-logo-container">
                        <img src={whiteKbcLogo} alt="white-kbc-logo" className="login-logo-mobile" />
                    </div>
                    <div className="login-header-mobile-text-container">
                        <p>Welcome to KBC SOS App</p>
                    </div>
                    
                    <div className="login-mobile-form-container">
                        {renderForm()}
                    </div>
                    <div className="login-mobile-contact-container">
                        <div className="login-mobile-enquiries-container">
                            <p>For any enquiries contact us.</p>
                            <p>info@kbcsafety.com</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="login-component-desktop">
                    <div className="login-component-left">

                        <div className="login-logo-container">
                            <img src={whiteKbcLogo} alt="white-kbc-logo" className="login-logo" />
                        </div>
                        <div className="login-text-container">
                            <p>Welcome to KBC SOS App</p>
                        </div>
                        <div className="login-contact-container">
                            <div className="login-social-media-container">
                                <FaLinkedin className="social-media-icon" />
                                <FaFacebook className="social-media-icon" />
                            </div>
                            <div className="login-enquiries-container">
                                <p>For any enquiries contact us.</p>
                                <p>info@kbcsafety.com</p>
                                
                                
                            </div>
                        </div>
                    </div>
                    
                    <div className="login-component-right-component-container">
                        <div className="login-component-right-container">
                            <div className="login-form-container-main">
                                <div className="login-right-header-text">
                                    <h1>Log In</h1>
                                    <p>Fill in your user details</p>
                                </div>
                                <div className="login-form-container">
                                    {renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {loginStatus === 'error' && <ToastError toasterError={serverError ? 'Server Error' : 'Error Logging In'} />}
            {loginStatus === 'success' && <LoginToaster />}
            
        </div>
    );
};

export default Login;