/* Component - Create SHE File.
    
    -   This compoent is to create the SHE File.  

*/

// React Components 
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../utils/apiConfig.js";

// CSS File 
import '../../../assets/css/Checklists.css'

// Components
import FormFieldsButton from "../FormFieldsButton.js"; // Form fields button contains all the predfined field buttons that add form fields into the checklist 
import CreateChecklistFormRenders from "./CreateChecklistFormRenders.js"; // Renders the checklist form sections 
import MobileDeviceChecklistRender from "./MobileDeviceChecklistRender.js"; // mobile device rendering


import { checklistSubmissionErrors } from "../errorChecklist.js";

// Toasters 

import ToasterChecklistCreated from "../../toasterscomponents/ToasterChecklistCreated.js";
import AddedChecklistToaster from "../../toasterscomponents/checklistToasters/AddedChecklistToaster.js";
import RemovedChecklistToaster from "../../toasterscomponents/checklistToasters/RemovedChecklistToaster.js";
import ErrorChecklistToaster from "../../toasterscomponents/checklistToasters/ErrorChecklistToaster.js";

// icons section
import { MdFormatListBulletedAdd } from "react-icons/md"; // radio section plus icon - add radio section
import { FaSort } from "react-icons/fa"; // radio section arrows icon = sort radio section
import { MdDeleteOutline } from "react-icons/md"; //
import { FaSpinner } from "react-icons/fa"; // Spinning for when loading

// functions 
import { addFormSection, removeFormSection, clearForm } from "./FunctionsFormSections.js";

// Component styles  
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; // Branding - White KBC Logo


// ------------------ Create SHE File Component ---------------------

const CreateSHEFile = () => {

    // Retrieve user data from session storage
    const user = JSON.parse(sessionStorage.getItem('user'));

    // -------------- State Management ------------------

    // state management - loading 
    const [isLoading, setIsLoading] = useState(false);

    // SHE File 
    const [formName, setFormName] = useState('');
    const [formSections, setFormSections] = useState([]); // initialize as an array
    const [activeSectionIndex, setActiveSectionIndex] = useState(null); // active section
    const [minimizedSections, setMinimizedSections] = useState([]); // minimize form section
    
    // state management - User interactive 
    const [buttonClicked, setButtonClicked] = useState(false);
    const [clearButtonClicked, setClearButtonClicked] = useState(false);

    // state management - toasters 
    const [showSuccess, setShowSuccess] = useState(false);
    const [showAddedChecklistSuccess, setShowAddedChecklistSuccess] = useState(false);
    const [showRemovedChecklist, setShowRemovedChecklist] = useState(false)
    

    // state management - Error Messages

    const [showCreateChecklistError, setShowCreateChecklistError] = useState(false); // New state for duplicate error
    const [showDuplicateError, setShowDuplicateError] = useState(false);
    const [showEmptyNameError, setShowEmptyNameError] = useState(false)
    const [showTextEmptyNameError, setShowTextEmptyNameError] = useState(false)
    const [showTextEmptySectionError, setShowTextEmptySectionError] = useState(false)
    const [showNumberError, setShowNumberError] = useState(false)
    const [showTextSpecialCharacterError, setShowTextSpecialCharacterError] = useState(false)

    // Array of error objects
    const errorMessages = [
        
        { show: showTextEmptySectionError, message: "Error! No checklist fields added, please add checklist fields." },
        { show: showDuplicateError, message: "Error! Duplicate checklist field name, cannot have checklist with the same name." },
        { show: showTextEmptyNameError, message: "Error! Checklist name empty, please fill in the checklist name." },
        { show: showTextSpecialCharacterError, message: "Error! Special Characters, checklist name cannot contain special characters." },
        { show: showNumberError, message: "Error! Checklist name or field name cannot only be numbers." }
      
    ];

    /* ----------------------- Functions ------------------------- */
   
    const navigate = useNavigate(); // navigation fucntions

    // function
    const handleClick = () => {

        setButtonClicked(true);
        setTimeout(() => setButtonClicked(false), 100); // Reset buttonClicked state after 300ms

    };

    // function - Call addFormSection function passing necessary parameters
    const handleAddFormSection = async (section) => {

        // Radio Predefined - risk rating 
        if (section.name === 'RiskRating') {
            try {
              const response = await axios.get(`${API_BASE_URL}/risk_rating_options`);
              section.options = response.data;
            } catch (error) {
              console.error('Error fetching risk rating options:', error);
              section.options = ['Low Risk', 'Medium Risk', 'High Risk']; // fallback
            }
          }

          // Radio Predefined - category
          if (section.name === 'Category') {

            try {
              const response = await axios.get(`${API_BASE_URL}/category_options`);
              section.options = response.data;
            } catch (error) {
              console.error('Error fetching risk rating options:', error);
              section.options = ['Procedure', 'House Keeping', 'People']; // fallback
            }

          }

          // Radio Predefined - observation outcome
          if (section.name === 'ObservationOutcome') {

            try {
              const response = await axios.get(`${API_BASE_URL}//observation_outcome_options`);
              section.options = response.data;
            } catch (error) {
              console.error('Error fetching observation outcome options:', error);
              section.options = ['Applicable', 'Not Applicable']; // fallback
            }

          }

        addFormSection(formSections, setFormSections, minimizedSections, setMinimizedSections, activeSectionIndex, setActiveSectionIndex, section);

        // Show success toaster
        setShowAddedChecklistSuccess(true);

        // Automatically hide the SuccessChecklistCreated component after 3 seconds
        setTimeout(() => {

            setShowAddedChecklistSuccess(false);
            
        }, 1000);

    }

    // Function - to remove a form section
    const handleRemoveFormSection = (index) => {
        removeFormSection(formSections, setFormSections, index);

        // Show success toaster
        setShowRemovedChecklist(true);

        // Automatically hide the SuccessChecklistCreated component after 3 seconds
        setTimeout(() => {

            setShowRemovedChecklist(false);
            
        }, 700);
    };

    // Function to clear formsections basicially to reset the SHEFIle
    const handleClearFormSection = () => {
        
        clearForm(setFormSections, setClearButtonClicked);
        clearFormAndName();

        // Show success toaster
        setShowRemovedChecklist(true);

        // Automatically hide the SuccessChecklistCreated component after 3 seconds
        setTimeout(() => {

            setShowRemovedChecklist(false);
            
        }, 1000);
    }

    // Toggle minimize 
    const toggleMinimize = (index) => {
        const newMinimizedSections = [...minimizedSections];
        newMinimizedSections[index] = !newMinimizedSections[index];
        setMinimizedSections(newMinimizedSections);
    };

    const toggleActiveSection = (index) => {
        setActiveSectionIndex(index);
        console.log('clicked')
    };

    // Function to handle input change for form name
    const handleFormNameChange = (e) => {
        setFormName(e.target.value);
    };

    // Option name change
    const handleOptionNameChange = (e, sectionIndex, optionIndex) => {
        const { name, value } = e.target;
        setFormSections(
            formSections.map((section, index) => {
                if (index === sectionIndex) {
                    if (name === "question") {
                        return { ...section, question: value };
                    } else if (name === "option") {
                        return { ...section, options: section.options.map((option, i) => i === optionIndex ? value : option) };
                    }
                }
                return section;
            })
        );
    };

    // Function to add an option to the radio section
    const addOptionToRadioSection = (index) => {
        setFormSections(formSections.map((section, i) => {
            if (i === index && section.inputField === 'radio') {
                const lastOptionIndex = section.options.length - 1;
                const lastOptionLabel = section.options[lastOptionIndex];
                const newOptionLabel = `Option ${parseInt(lastOptionLabel.split(' ')[1]) + 1}`;
                return { ...section, options: [...section.options, newOptionLabel] };
            }
            return section;
        }));
    };

    // Function to handle change in radio button options
    const handleRadioOptionChange = (e, sectionIndex, optionIndex) => {
        const { value } = e.target;
        setFormSections(formSections.map((section, index) => {
            if (index === sectionIndex && section.inputField === 'radio') {
                return { ...section, options: section.options.map((option, i) => i === optionIndex ? value : option) };
            }
            return section;
        }));
    };

    // Function to remove an option from a radio section
    const removeOptionFromRadioSection = (sectionIndex, optionIndexToRemove) => {
        setFormSections(formSections.map((section, index) => {
            if (index === sectionIndex && section.inputField === 'radio') {
                return { ...section, options: section.options.filter((_, optionIndex) => optionIndex !== optionIndexToRemove) };
            }
            return section;
        }));
    };

    // Function to clear the form and name
    const clearFormAndName = () => {

        setFormName(''); // Reset form name to empty string
        setFormSections([]); // Reset form sections to an empty array
        setShowCreateChecklistError(false);
        setShowDuplicateError(false)
        setShowTextEmptyNameError(false)
        setShowTextEmptySectionError(false)
        setShowTextSpecialCharacterError(false);
        setShowNumberError(false)

    };

    // function Navigate back
    const handleNavigateBack = () => {

    navigate('/checklistmanagement')
    

  }

    

    const handleSubmit = async (event) => {

        event.preventDefault();
        //
        setIsLoading(true); // Set loading to true when submission starts


        // Check for submission errors
        if (checklistSubmissionErrors(
            formSections,
            formName,
            setShowTextEmptyNameError,
            setShowEmptyNameError,
            setShowDuplicateError,
            setShowCreateChecklistError,
            setShowNumberError,
            setShowTextEmptySectionError,
            setShowTextSpecialCharacterError)) {

            setIsLoading(false); // Set loading to false if there are errors
            return; // Prevent form submission if there are errors
        }

        


        try {

            const formData = {

                formName,
                formSections: formSections.map(section => ({
                    
                    name: section.name,
                    inputField: section.inputField,
                    isRequired: section.isRequired,
                    question: section.question,
                    disabled: section.disabled,
                    
                    //options: section.options, // Include all options for all sections
                    //selectedOption: section.selectedOption // Include selected option for radio button sections
                }))
            };

            // Create Table in Database
            const tableCreationResponse = await axios.post(`${API_BASE_URL}/create_table`, formData);
            console.log('Table created successfully:', tableCreationResponse.data);

            console.log('Form Data ', formData);

            // Submit sections (Columns) to the database
            // const formSubmissionResponse = await axios.post("http://localhost:5000/submit_form", formData);
            // console.log('Form submitted successfully:', formSubmissionResponse.data);

            // Show success toaster
            setIsLoading(false); // Set loading to false when submission is successful
            setShowSuccess(true);

            // Automatically hide the SuccessChecklistCreated component after 3 seconds
            setTimeout(() => {
                
                setShowSuccess(false);
                navigate('/viewchecklist');

            }, 2000);

            // Clear the form and name
            handleClick(); // UX Component gives user button feedback
            clearFormAndName();
            
            setShowCreateChecklistError(false);
            setShowDuplicateError(false)
            setShowTextEmptyNameError(false)
            

        } catch (error) {

            console.error('Error submitting form:', error);
            setIsLoading(false); // Set loading to false when submission is successful

        }
    };

    return (

        <div className="component-container"> 

            <div className="create-checklist-component-container">
                
                <div className="create-checklist-text-container">
                    <h1> Create SHE file </h1>
                    <p>Create a SHE file tailored to your requirements</p>
                </div>

                {/* Navigate back */}
                <div className="navigate-back-button-main-container">

                    <div className="navigate-back-button-container">
                        <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                    </div>

                </div>

                <div className="form-container">

                    <form onSubmit={handleSubmit}>

                        <div className="form-body-container">
                            <div className="form-body-left-container">
                                <div className="form-element-container">
                                    <div className="form-label-container">
                                        <label><h2>SHE file Name </h2></label>
                                        <p>Please fill in the SHE file name</p>
                                    </div>
                                    <div className="form-label-container">
                                        <input 
                                            placeholder="Enter SHE File Name" 
                                            className="from-name-input"
                                            value={formName}
                                            onChange={handleFormNameChange}
                                        />
                                    </div>
                                </div>

                                {/* Renders sections into custom form section  */}
                                <CreateChecklistFormRenders
                                    formSections={formSections}
                                    activeSectionIndex={activeSectionIndex}
                                    toggleActiveSection={toggleActiveSection}
                                    handleRemoveFormSection={handleRemoveFormSection}
                                    minimizedSections={minimizedSections}
                                    toggleMinimize={toggleMinimize}
                                    handleOptionNameChange={handleOptionNameChange}
                                    handleRadioOptionChange={handleRadioOptionChange}
                                    addOptionToRadioSection={addOptionToRadioSection}
                                    removeOptionFromRadioSection={removeOptionFromRadioSection}
                                    setFormSections={setFormSections}
                                />

                                {/* Rendering for error messages  */} 
                                <div className="error-text-container">
                                    {errorMessages.map((error, index) => (

                                        error.show && (
                                            <div key={index} className="error-text">
                                                <p>{error.message}</p>
                                            </div>
                                        )
                                    ))}

                                </div>
                                

                                <div>
                                    <div>
                                        <p>By clicking create SHE File button, checklist will be created. </p>
                                    </div>
                                </div>

                                <div className="create-clear-form-button-containers">

                                    <button 
                                        type="submit" 
                                        className="login-form-button"
                                        disabled={isLoading}
                                    >
                                    
                                    {isLoading ? (
                                        <>
                                        <FaSpinner className="fa-spin" />
                                        </>
                                    ) : 'Create SHE File'}

                                    </button>
                                    
                                    <div>
                                        <button type="button" className={`clear-form-button ${clearButtonClicked ? 'grow' : ''}`}  onClick={handleClearFormSection}>Clear SHE file</button>
                                    </div>

                                </div>

                                
                                
                            </div>

                            {/* Form Body Right container  */}
                            <div className="form-body-right-container">

                                <div className="form-fields-container">

                                    <div className="form-fields-text-container">

                                        <h2>SHE file fields</h2>

                                    </div>

                                    <div className="form-fields-containers">

                                        <FormFieldsButton addFormSection={handleAddFormSection} />

                                    </div>

                                </div>
                                

                                {/* Use the MobileDeviceForm component */}
                                <MobileDeviceChecklistRender formName={formName} formSections={formSections} />

                            </div>
                        </div>
                        
                        {showSuccess && <ToasterChecklistCreated />}
                        {showAddedChecklistSuccess && <AddedChecklistToaster />}
                        {showRemovedChecklist && <RemovedChecklistToaster />}

                        {showCreateChecklistError && 
                            < ErrorChecklistToaster />
                        }

                        {showEmptyNameError && 
                            <ErrorChecklistToaster />
                        }
                        
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateSHEFile;
