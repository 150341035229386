import React from "react";
import { Link } from "react-router-dom";

// Componenets 
import UserInfoHeader from "../UserInfoHeader"; //

//icons 
import { TfiWrite } from "react-icons/tfi"; // Fill in form - paper with pencil 
import { CiCirclePlus } from "react-icons/ci"; // Create Form - Magnifying glass
import { SlMagnifier } from "react-icons/sl"; // View forms - Create icon
import { MdOutlineEdit } from "react-icons/md"; // Edit / Delete forms - Pen

import { FaRegFileAlt } from "react-icons/fa";

// CSS File 
import '../../assets/css/Checklists.css';

const ChecklistManagement = () => {

    const user = JSON.parse(sessionStorage.getItem('user'));

    

    return (

        <div className="component-container">

            <div className="checklist-component-container">

                <div className="checklist-text-container">

                    <div>
                        <h1>SHE File Management</h1>
                    </div>

                   

                </div>

                

                {/* Navigations to applicaiton pages */}
                <div className="checklist-feature-main-container">

                {/* Create Checklist */}  
                {user.userRole !== 'user' &&  (
                    
                    
                    <Link to='/createSHEFile' className="link">

                    
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <CiCirclePlus className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>Create She File</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                Create a SHE Pack tailored to your requirements
                            </p>

                        </div>

                    </div>
                    
                    </Link>
                )}

                <Link to='/shefileitems' className="link">

                    
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <FaRegFileAlt className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>SHE File Items</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                Create a SHE File items 
                            </p>

                        </div>

                    </div>
                    
                    </Link>

                    {/* Fill In Checklist  */}
                    <Link to='/fillinchecklist' className="link">

                    {/* Fill Checklist */}
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <TfiWrite className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>Fill in SHE file  </h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                Fill in the available she file
                            </p>

                        </div>

                    </div>
                    
                    </Link>

                    {/* View Form */}
                    <Link to='/viewchecklist' className="link">

                    {/* View form */}
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <SlMagnifier className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>View SHE file</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                View and manage available SHE File
                            </p>

                        </div>

                    </div>
                    
                    </Link>

                    


                </div>



            </div>

        </div>

    )
}

export default ChecklistManagement