import React from "react";

import { MdErrorOutline } from "react-icons/md";

// CSS File 
import '../../../assets/css/Toasters.css';

const ErrorChecklistToaster = () => {

    return (

        <div className="added-checklist-section-component-container">

            <div className="added-checklist-section-container">

                <div className="added-checklist-section-tick-container">

                    <MdErrorOutline className="added-checklist-section-error" />
                    
                </div>

                <div className="added-checklist-section-text-container">

                    <div>
                        <p> Error Creating checklist  </p>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ErrorChecklistToaster