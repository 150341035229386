import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import PrivateRoute from './utils/PrivateRoute';

import RoleBasedRoute from './components/utils/Authentication/RoleBasedRoute';

/* Header */
import Header from './components/Header';
import UserInfoHeader from './components/UserInfoHeader';

/* Login  */
import Login from './components/Login';

/* Dashbaord */
import Dashboard from './components/dashboardcomponents/Dashboard';

/* Checklist Management */
import ChecklistManagement from './components/checklistmanagementcomponents/Checklist';
import CreateSHEFile from './components/checklistmanagementcomponents/createchecklistcomponents/CreateSHEFile';
import FillInChecklist from './components/checklistmanagementcomponents/fillinchecklistcomponents/FillInChecklist';

import ViewChecklist from './components/checklistmanagementcomponents/viewchecklistcomponents/ViewCheckList';

import SheFileItemsComponent from './components/checklistmanagementcomponents/shefileitemscomponents/SheFileItemsComponent';

/* Manage Users */
import ManageUsers from './components/manageuserscomponents/ManagerUsers';
import CreateCompanyComponent from './components/manageuserscomponents/createcompanycomponents/CreateCompanyComponent';
import CreateUserComponent from './components/manageuserscomponents/createusercomponents/CreateUserComponent';
import ViewUsersComponent from './components/manageuserscomponents/viewuserscomponents/Viewuserscomponent';
import AssignUserComponent from './components/manageuserscomponents/assignuserscomponents/AssignUserComponent';

/* Profile Components  */
import ProfileComponents from './components/Profilecomponents/ProfileComponents';

/* Help Components */
import HelpComponents from './components/helpcomponents/HelpComponents';

function App() {
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [userRole, setUserRole] = useState('');

  const PrivateRoute = ({ isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/" />;
  };

  

  

  /*
  useEffect(() => {
    // Check if user is authenticated when the app loads
    const authStatus = JSON.parse(sessionStorage.getItem('isAuthenticated'));
    if (authStatus) {
      setIsAuthenticated(authStatus);
    } else {
      // If not authenticated and trying to access the homepage, clear session and redirect to login
      if (window.location.pathname === '/') {
        sessionStorage.removeItem('isAuthenticated');
        setIsAuthenticated(false);
      }
    }
  }, []);
  */

  useEffect(() => {
    const authStatus = JSON.parse(sessionStorage.getItem('isAuthenticated'));
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (authStatus && user && user.userRole) {
      setIsAuthenticated(authStatus);
      setUserRole(user.userRole);
    } else {
      if (window.location.pathname !== '/') {
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        setIsAuthenticated(false);
        setUserRole('');
        window.location.replace('/');
      }
    }
  }, []);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
};

  const handleAuthentication = (status, user) => {
    setIsAuthenticated(status);
    if (status && user && user.userRole) {
      setUserRole(user.userRole);
      sessionStorage.setItem('isAuthenticated', JSON.stringify(status));
      sessionStorage.setItem('user', JSON.stringify(user));
    } else {
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('user');
      setUserRole('');
      setIsAuthenticated(false);
    }
  };

  return (

    <BrowserRouter className='main-div'>

      <div className="application-component-container">

        <div className="application-container">

          {isAuthenticated ? (

            <div className="application-layout">

              {/* Application - side bar menu */}
              <div className={`app-container ${isCollapsed ? 'header-collapsed' : ''}`}>

                <Header 
                  className='header-container ' 
                  isAuthenticated={isAuthenticated} 
                  onAuthentication={handleAuthentication}
                  isCollapsed={isCollapsed}
                    toggleCollapse={toggleCollapse}
                />
              
              </div>

              <div className="main-content-container">
                {/* Application - info */}
                <div className="app-user-info-header-container">
                  <UserInfoHeader className='user-info-header-container' isAuthenticated={isAuthenticated} onAuthentication={handleAuthentication} />
                </div>

                <div className="app-routes-main-container">
                <Routes>
                  {/* Dashboard */}
                  <Route 
                    path="/dashboard" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin', 'user']}>
                        <Dashboard />
                      </RoleBasedRoute>
                    } 
                  />

                  {/* Checklist Management */}
                  <Route 
                    path="/checklistmanagement" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin', 'user']}>
                        <ChecklistManagement />
                      </RoleBasedRoute>
                    } 
                  />

                  <Route 
                    path="/createSHEFile" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin']}>
                        <CreateSHEFile />
                      </RoleBasedRoute>
                    } 
                  />



                <Route 
                    path="/shefileitems" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin']}>
                        <SheFileItemsComponent />
                      </RoleBasedRoute>
                    } 
                  />


                  <Route 
                    path="/fillinchecklist" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin', 'user']}>
                        <FillInChecklist />
                      </RoleBasedRoute>
                    } 
                  />

                  <Route 
                    path="/viewchecklist" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin', 'user']}>
                        <ViewChecklist />
                      </RoleBasedRoute>
                    } 
                  />

                  {/* Manage users (admin only) */}
                  <Route 
                    path="/managerusers" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin']}>
                        <ManageUsers />
                      </RoleBasedRoute>
                    } 
                  />
                  <Route 
                    path='/createcompany' 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                        <CreateCompanyComponent />
                      </RoleBasedRoute>
                    } 
                  />
                  <Route 
                    path='/createuser' 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                        <CreateUserComponent />
                      </RoleBasedRoute>
                    } 
                  />
                  <Route 
                    path='/viewusers' 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin']}>
                        <ViewUsersComponent />
                      </RoleBasedRoute>
                    } 
                  />
                  
                  <Route 
                    path='/assignusers' 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin']}>
                        <AssignUserComponent />
                      </RoleBasedRoute>
                    } 
                  />

                  {/* Profile and Help (both roles) */}
                  <Route 
                    path="/profile" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin', 'user']}>
                        <ProfileComponents />
                      </RoleBasedRoute>
                    } 
                  />
                  <Route 
                    path="/help" 
                    element={
                      <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin','admin', 'user']}>
                        <HelpComponents />
                      </RoleBasedRoute>
                    } 
                  />

                  <Route path="*" element={<Navigate to="/dashboard" />} />
                </Routes>
                </div>
              </div>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Login onAuthentication={handleAuthentication} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          )}
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;