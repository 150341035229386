import React from "react";

import { MdErrorOutline } from "react-icons/md";

// CSS File 
import '../../assets/css/Toasters.css';

const ToastError = ({toasterError}  ) => {

    return (

        <div className="added-checklist-section-component-container">

            <div className="added-checklist-section-container">

                <div className="added-checklist-section-tick-container">

                    <MdErrorOutline className="added-checklist-section-error" />
                    
                </div>

                <div className="added-checklist-section-text-container">

                    <div>
                        <p> {toasterError}  </p>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ToastError