
import React from 'react';

import { MdDeleteOutline , MdFormatListBulletedAdd } from "react-icons/md"; //
import { FaSort } from "react-icons/fa"; // radio section arrows icon = sort radio section

import { CiBoxList } from "react-icons/ci";

// CSS File 
import '../../../assets/css/Checklists.css'

const CreateChecklistFormRenders = ({
  
    formSections,
    activeSectionIndex,
    toggleActiveSection,
    handleRemoveFormSection,
    minimizedSections,
    toggleMinimize,
    handleOptionNameChange,
    handleRadioOptionChange,
    addOptionToRadioSection,
    removeOptionFromRadioSection,
    setFormSections

  }) => {
    
    return (
      <div className="form-sections-rendering-container">
        {/* Render form sections */}
        {formSections.map((section, index) => (
          <div 
            className={`section-container ${index === activeSectionIndex ? 'active-form-section' : ''}`}
            key={index}
            onClick={() => toggleActiveSection(index)}
          >
            <div className="section-add-remove-button-container">
              <div>
                <p>{index + 1} - {section.name} form section</p>
              </div>
              <div className="section-button-container">
                {/*<div>
                  <FaSort />
                </div>
                */}
                <div>
                  <MdDeleteOutline onClick={() => handleRemoveFormSection(index)} /> {/*Removes form section from checklist*/}
                </div>
                <div>
                  <button type='button' className='form-section-minimize-button' onClick={() => toggleMinimize(index)}>Minimize</button>
                </div>
              </div>
            </div> 
            {!minimizedSections[index] && (
              <>
                {/* Checkbox for Required */}

                {/*}
                <div className="form-section-checkbox-container">
                  <div>
                    <input
                      type="checkbox"
                      checked={section.isRequired}
                      onChange={(e) => {
                        setFormSections(formSections.map((s, i) => i === index ? { ...s, isRequired: e.target.checked } : s));
                      }}
                    />
                  </div>
                  <div>
                    <label>Required - Select checkbox if {section.name} is mandatory.</label>
                  </div>
                </div> 

                */}
  
                {/* Conditional rendering based on inputfield type */}
                {section.inputField === 'name' ? (
                  <div className="section-name-container">
                    <p>Predifined Checklist section : {section.name}</p> {/* Question */}
                    <input
                      className="predefined-question-input"
                      type="text"
                      name="question"
                      value={section.question}
                      placeholder="Enter question"
                      disabled
                    />
                    <div className="section-description-container">
                      <h5>{section.name} Checklist Section Description</h5>
                      <div className="section-description-text-container">
                        <p>Maximum characters allowed (1 to 20 letters)</p>
                        <p>No numbers</p>
                        <p>No special characters</p>
                      </div>
                    </div> 
                  </div>
                ) : section.inputField === 'date' ? (

                  <div className="section-name-container">

                    <p>Predifined Checklist section : {section.name}</p> {/* Question */}
                    
                    <input
                      className="predefined-question-input"
                      type="text"
                      name="question"
                      value={section.question}
                      placeholder="Enter question"
                      disabled
                    />
                    <div className="section-description-container">
                      <h5>{section.name} Checklist Section Description</h5>
                      <div className="section-description-text-container">
                        <p>Date format yyyy/mm/dd</p>
                      </div>
                    </div> 
                  </div>
                ) : section.inputField === 'phone' ? (
                  <div className="section-name-container">

                    <p>{section.name} -  Predefined checklist section</p> {/* Question */}

                    <input
                      type="text"
                      name="question"
                      value={section.question}
                      placeholder="Enter your question"
                      className="predefined-question-input"
                      disabled
                    />

                    <div className="section-description-container">
                      <h5>{section.name} Checklist Section Description</h5>
                      <div className="section-description-text-container">
                        <p>Maximum of 10 digits allowed</p>
                      </div>
                    </div> 
                  </div>

                ) : section.inputField === 'address' ? (
                  <div className="section-name-container">
                    <p>{section.name} -  Predefined checklist section</p> {/* Question */}
                    <input
                      className="predefined-question-input"
                      type="text"
                      name="question"
                      value={section.question}
                      placeholder=" Enter your question "
                      disabled
                    />
                    <div className="section-description-container">
                      <h5>{section.name} Checklist Section Description</h5>
                      <div className="section-description-text-container">
                        <p>Maximum characters allowed 50</p>
                      </div>
                    </div>
                  </div>
                  
                ) : section.question === 'Photo' ? (

                  <div className="section-name-container">

                    <p>{section.name} -  Predefined checklist section</p> {/* Question */}

                    <input
                      className="predefined-question-input"
                      type="file"
                      name="question"
                      value=''
                      placeholder=" Enter your question "
                      disabled
                    />

                    <div className="section-description-container">
                      <h5>{section.name} Checklist Section Description</h5>
                      <div className="section-description-text-container">
                        <p>Maximum file size : 10mb</p>
                        <p>Maximum file size : Png, JPEG</p>
                      </div>
                    </div>
                  </div>

                ) : section.inputField === 'customText' ? (
                  <div className="section-name-container">
                    <p>Custom Text Field : {section.name} </p> {/* Question */}
                    <input
                      className="predefined-question-input"
                      type="text"
                      name="question"
                      value={section.question}
                      placeholder=" Enter your question "
                      disabled
                    />
                    <div className="section-description-container">
                      <h4>Custom textfield description : </h4>
                      <div className="section-description-text-container">
                        <p>Custom textfield contains both text and numbers.</p>
                      </div>
                    </div>
                  </div>

                ) : section.inputField === 'customFileFieldInput' ? (

                  <div className="section-name-container">
                    <p>Custom Text Field : {section.name} </p> {/* Question */}

                    <input
                      className="predefined-question-input"
                      type="file"
                      name="question"
                      value=''
                      placeholder=" Enter your question "
                      disabled
                    />
                    
                    <div className="section-description-container">
                      <h4>Custom textfield description : </h4>
                      <div className="section-description-text-container">
                        <p>Custom textfield contains both text and numbers.</p>
                      </div>
                    </div>
                  </div>

                ) : section.name === 'RiskRating' ? (

                  <div className="section-input-container">

                    <div className="section-name-container">
                      <p>Predefined Field : {section.name} </p> {/* Question */}
                    </div>
                    
                    {section.options.map((option, optionIndex) => (
                      <div key={optionIndex} className="section-radio-field-container">
                        
                        <input className="radio-input-question-main" type="radio" id={`option-${optionIndex}`} name={`radio-${index}`} value={option} />
                        
                        <input

                          className="radio-input-question"
                          type="text"
                          value={option}
                          onChange={(e) => handleRadioOptionChange(e, index, optionIndex)}
                          disabled
                          
                        />
                        
                        <div className="section-radio-add-remove-button-container">
                          {/*<div>
                            <FaSort /> 
                          </div>
                           */}
                          

                        </div>
                      </div>
                    ))}
                  </div>

                  
                ) : section.name === 'Category' ? (

                  <div className="section-input-container">

                    <div className="section-name-container">
                      <p>Predefined Field : {section.name} </p> {/* Question */}
                    </div>
                    
                    {section.options.map((option, optionIndex) => (
                      <div key={optionIndex} className="section-radio-field-container">
                        
                        <input className="radio-input-question-main" type="radio" id={`option-${optionIndex}`} name={`radio-${index}`} value={option} />
                        
                        <input

                          className="radio-input-question"
                          type="text"
                          value={option}
                          onChange={(e) => handleRadioOptionChange(e, index, optionIndex)}
                          disabled
                          
                        />
                        
                        <div className="section-radio-add-remove-button-container">
                          {/*<div>
                            <FaSort /> 
                          </div>
                           */}
                          

                        </div>
                      </div>
                    ))}
                  </div>

                  
                ) :  section.name === 'ObservationOutcome' ? (

                  <div className="section-input-container">

                    <div className="section-name-container">
                      <p>Predefined Field : {section.name} </p> {/* Question */}
                    </div>
                    
                    {section.options.map((option, optionIndex) => (
                      <div key={optionIndex} className="section-radio-field-container">
                        
                        <input className="radio-input-question-main" type="radio" id={`option-${optionIndex}`} name={`radio-${index}`} value={option} />
                        
                        <input

                          className="radio-input-question"
                          type="text"
                          value={option}
                          onChange={(e) => handleRadioOptionChange(e, index, optionIndex)}
                          disabled
                          
                        />
                        
                        <div className="section-radio-add-remove-button-container">
                          {/*<div>
                            <FaSort /> 
                          </div>
                           */}
                          

                        </div>
                      </div>
                    ))}
                  </div>

                  
                ): (
                  <div className="section-name-container">
                    <p>Predifined Checklist section {section.name}</p> {/* Question */}
                    <input
                      type="text"
                      name="question"
                      value={section.question}
                      placeholder="Enter question"
                      onChange={(e) => handleOptionNameChange(e, index)}
                    />
                  </div>
                )}
  
                {/* Conditional rendering based on inputfield type */}
                {section.inputField === '' ? (
                  <div className="section-input-container">
                    {section.options.map((option, optionIndex) => (
                      <div key={optionIndex} className="section-radio-field-container">
                        <input type="radio" id={`option-${optionIndex}`} name={`radio-${index}`} value={option} />
                        <input
                          className="radio-input-question"
                          type="text"
                          value={option}
                          onChange={(e) => handleRadioOptionChange(e, index, optionIndex)}
                        />
                        <div className="section-radio-add-remove-button-container">
                          {/*<div>
                            <FaSort /> 
                          </div>
                           */}
                          

                          <div>
                            <MdFormatListBulletedAdd onClick={() => addOptionToRadioSection(index)} /> {/* Add Radio section New Section */}
                          </div>
                          {section.options.length > 1 ? (
                            <div>
                              <MdDeleteOutline
                                className="section-radio-add-remove-button"
                                onClick={() => removeOptionFromRadioSection(index, optionIndex)}
                              />
                            </div>
                          ) : (
                            <div>
                              <MdDeleteOutline className="section-radio-add-remove-button dull" disabled />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="section-input-container">
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default CreateChecklistFormRenders;