import React from "react";

import { Link } from "react-router-dom";


// Components
import UserInfoHeader from "../UserInfoHeader"; // user info component


/* Dashboard Component 

- After user has logged in they'll be directed to this component.
- Dashboard component contains the following buttons : Checklists Management, manage users, profile, help*/

// icons 
import { CiFolderOn } from "react-icons/ci"; // Checklist icon
import { LuUsers } from "react-icons/lu"; // manage Users icon
import { LuUserCog } from "react-icons/lu"; // View and edit profile
import { IoIosHelpCircleOutline } from "react-icons/io"; // Help

import sosGraph from '../../assets/images/other/SOS_Graph.png';
import sosGraph1 from '../../assets/images/other/SOS_graph_1.png';


const Dashboard = () => {

    const user = JSON.parse(sessionStorage.getItem('user'));


    return (

        <div className="component-container">

            <div className="dashboard-component-container">

                

                <div className="welcome-text-container">

                    <div>
                        <h1>Welcome to KBC SOS</h1>
                    </div>

                    <div>
                        <p>KBC Safety Officer Support</p>
                    </div>

                </div>

                {/* Navigations to applicaiton pages */}
                <div className="application-feature-main-container">

                    <div>
                        <img src={sosGraph1} alt="white-kbc-logo" className="sos_graph1" />
                    </div>

                    <div>
                        <img src={sosGraph} alt="white-kbc-logo" className="sos_graph" />
                    </div>

                    

                    

                </div>

            </div>


        </div>
    )
}

export default Dashboard