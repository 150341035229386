import React from 'react';
import { Navigate } from 'react-router-dom';

const RoleBasedRoute = ({ isAuthenticated, userRole, allowedRoles, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (allowedRoles.includes(userRole)) {
    return children;
  }

  return <Navigate to="/dashboard" />;
};

export default RoleBasedRoute;