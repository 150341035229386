export const checklistSubmissionErrors = (
    formSections, 
    formName, 
    setShowTextEmptyNameError, 
    setShowEmptyNameError,
    setShowDuplicateError, 
    setShowCreateChecklistError, 
    setShowNumberError,
    setShowTextEmptySectionError,
    setShowTextSpecialCharacterError
    ) => {

    // Check for duplicate sections
    const sectionNames = formSections.map(section => section.name);
    const hasDuplicates = sectionNames.some((name, index) => sectionNames.indexOf(name) !== index);

    // Check if the checklist name is empty
    if (!formName.trim()) {
        setShowTextEmptyNameError(true);
        setShowEmptyNameError(true);
        setTimeout(() => setShowEmptyNameError(false), 4000); // Hide after 3 seconds
        return true; // Prevent form submission
    }

    // Check if there are duplicate section names
    if (hasDuplicates) {
        setShowDuplicateError(true);
        setShowCreateChecklistError(true);
        setTimeout(() => setShowCreateChecklistError(false), 4000); // Hide after seconds
        return true; // Prevent form submission
    }

    // Check if the checklist name contains only numbers
    if (/^\d+$/.test(formName)) {

    setShowNumberError(true)
    setShowCreateChecklistError(true);

    setTimeout(() => setShowCreateChecklistError(false), 4000); // Hide after 4 seconds

    return true; // Prevent form submission

    }

    // Check if there are no form sections added
    if (formSections.length === 0) {

        setShowTextEmptySectionError(true);
        setShowCreateChecklistError(true);
        setTimeout(() => setShowCreateChecklistError(false), 4000); // Hide after seconds

        return true; // Prevent form submission

    }

    // Check if the checklist name contains special characters
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
    if (specialCharPattern.test(formName)) {

        setShowTextSpecialCharacterError(true)
    
        setShowCreateChecklistError(true);
        setTimeout(() => setShowCreateChecklistError(false), 4000); // Hide after seconds
        return true; // Prevent form submission

    }

    /// Check if any section name contains special characters
    for (let section of formSections) {
        if (specialCharPattern.test(section.name)) {

            setShowTextSpecialCharacterError(true);
            setShowCreateChecklistError(true);

            setTimeout(() => setShowCreateChecklistError(false), 4000); // Hide after seconds
            return true; // Prevent form submission

        }
    }

    return false; // Indicate that there are no errors
}