import React from "react";

import { SiTicktick } from "react-icons/si";

// CSS File 
import '../../assets/css/Toasters.css';

const FilledInChecklistToaster = () => {

    return (

        <div className="login-toaster-component-container">

            <div className="login-toaster-container">

                <div className="logins-tick-container">
                    <SiTicktick className="login-tick" />
                </div>

                <div className="logins-text-container">

                    <div>
                        <h1> Checklist Submitted</h1>
                    </div>

                    <div className="logins-text-container">
                        <p>One moment. Redirecting you to View Checklist page</p>
                    </div>

                </div>

                <div className="loading-circle">
                
                </div> {/* Replaced the "O" with the loading circle */}

                

            </div>

        </div>
    )
}

export default FilledInChecklistToaster