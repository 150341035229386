import React from "react";

import { MdErrorOutline } from "react-icons/md";

// CSS File 
import '../../../assets/css/Toasters.css';

const ConfirmdeleteTableToaster = ({ onConfirm, onCancel }) => {

    return (

        <div className="confirm-delete-toaster-component-container">

            <div className="confirm-delete-toaster-container">

                <div className="logins-tick-container">

                    <div>
                        <MdErrorOutline className="confirm-delete-icon" />
                    </div>
        
                </div>

                <div className="confirm-delete-text-container">

                    <div >
                        <h2>Delete Checklist Confirmation</h2>
                    </div>

                    <div className="logins-text-container">
                        <p>Are you sure that you want to delete checklist ? By Clicking yes, checklist will be permantly deleted.</p>
                    </div>

                </div>

                

                <div className='confirm-delete-button-container-container'>

                    <div className="confirm-delete-button-container">
                        <button className="confirm-delete-button-yes" onClick={onConfirm}>Yes</button>
                    </div>

                    <div className="confirm-delete-button-container">
                        <button className="confirm-delete-button-no" onClick={onCancel}>No</button>
                    </div>
                </div>

                

                

            </div>

        </div>
    )
}

export default ConfirmdeleteTableToaster