import React from "react";

import { useNavigate } from "react-router-dom";

import { useState } from "react";

import axios from "axios";

import { MdOutlineConstruction } from "react-icons/md";

import blueKbcLogo from '../../assets/images/branding/blue-kbc-logo.png';

//toaster
import CreatedSuccessfullToaster from "../toasterscomponents/manageusersToaster/createdSuccessfullToaster";
import ToastError from "../toasterscomponents/ToastError";
// CSS File 
import '../../assets/css/Profile.css';

const ProfileComponents = () => {

    const navigate = useNavigate()

    //state managements 
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [errorToaster, setErrorToaster] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    

    const [successsToaster, setSuccessToaster] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');

    const handleClear = () => {

        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setErrorMessage('')
        setSuccessMessage('')

    }

    // Navigate back
    const handleNavigateBack = () => {

        navigate('/')
      
      }

    //
    const handlePasswordChange = async () => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!passwordRegex.test(newPassword)) {
            setErrorMessage("Password must contain at least one special character, one letter, and one number");
            setErrorToaster(true);
            setTimeout(() => setErrorToaster(false), 2000);
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage("New passwords do not match");
            setErrorToaster(true);
            setTimeout(() => setErrorToaster(false), 2000);
            return;
        }

        try {
            const response = await axios.post('/api/change_password', {
                userId: user.id,
                currentPassword,
                newPassword,
            });

            if (response.status === 200) {
                setSuccessMessage("Password changed successfully");
                setSuccessToaster(true);
                setTimeout(() => {
                    setSuccessToaster(false);
                    navigate('/');
                }, 2000);
            } else {
                setErrorMessage("Failed to change password");
                setErrorToaster(true);
                setTimeout(() => setErrorToaster(false), 2000);
            }

            handleClear();
        } catch (error) {
            setErrorMessage(error.response.data.error || "Failed to change password");
            setErrorToaster(true);
            setTimeout(() => setErrorToaster(false), 2000);
        }
    }

    // Retrieve user data from session storage
    const user = JSON.parse(sessionStorage.getItem('user'));

    // Function to get initials from username and surname
    const getInitials = (userName, userSurname) => {
        const initials = userName.charAt(0) + userSurname.charAt(0);
        return initials.toUpperCase(); // Convert to uppercase
    };

    

    return (

        <div className="component-container"> 

            <div className="profile-component-container">

                <div className="profile-container">

                    <div className="profile-branding-container">
                        
                        <div>
                            <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
                        </div>

                        <div>
                            <button type="button" className="profile-back-button" onClick={handleNavigateBack}>Close</button>
                        </div>

                    </div>

                    <div className="profile-userinfo-main-container">

                        {/* User initials, name , surrname & userID */}
                        <div className="profile-user-userinfo-container">

                            <div className="profile-user-role-initials-container">
                                <p> {getInitials(user.userName, user.userSurname)} </p>
                            </div>

                            <div className="profile-userfullname-contianer">
                                <h2>{`${user.userName} ${user.userSurname}`} </h2>
                                <p>User ID : {user.id}</p>
                                <p>User Role : {user.userRole}</p>
                            </div>

                        </div>

                        {/* Contact Detials */}
                        <div className="profile-user-contact-details-container">

                            <div className="profile-user-contact-details-text-container">
                                <h3>Contact Details</h3>
                            </div>

                            <div className="profile-user-contact-details-phone-email-container">
                                <div>
                                    <p>Phone Number : {user.userPhone}</p>
                                </div>

                                <div>
                                    <p>Email Address : {user.userEmail}</p>
                                </div>
                            </div>
                        
                        </div>

                        {/* Password  */}
                        <div className="profile-user-password-details-container">

                        <div className="profile-user-contact-details-text-container">
                            <h3>Password</h3>

                            <p>To change your  password, please fill in the fields below.
                            </p>
                        </div>

                        <div className="profile-user-password-form-details-container">

                            <div className="profile-user-password-current-new-confirm-container">

                                <div>

                                    <div>
                                        <label>
                                            <p>Current Password</p>
                                        </label>
                                    </div>

                                    <div className="create-company-form-input-container">
                                        <input
                                            type="password"
                                            value={currentPassword}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            placeholder="Enter current password"
                                            className="profile-password-input-fields"
                                        />
                                    </div>

                                </div>

                                <div>

                                    <div>
                                        <label>
                                            <p>Create a new password</p>
                                        </label>
                                    </div>

                                    <div className="create-company-form-input-container">
                                        <input
                                            type="password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            placeholder="Enter new password"
                                            className="profile-password-input-fields"
                                        />
                                    </div>

                                </div>

                                <div>

                                    <div>
                                        <label>
                                            <p>Confirm new password</p>
                                        </label>
                                    </div>

                                    <div className="create-company-form-input-container">
                                        <input
                                            type="password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            placeholder="Enter new password"
                                            className="profile-password-input-fields"
                                        />
                                    </div>

                                </div>

                            </div>

                            <div className="profile-user-password-structure-container">

                                <div>
                                    <p>*Password must contain at least one special character, one letter and one number</p>
                                </div>

                            </div>
                            
                        </div>

                        <div>
                            <div>
                                {errorMessage && <p className="error-text">{errorMessage}</p>}
                                {successMessage && <p className="error-text">{successMessage}</p>}
                            </div>
                        </div>

                        <div>

                            <div>

                                <div className="profile-confirm-terms-and-conditions-container">
                                    <p>By clicking confirm your password will be changed to your new password</p>
                                </div>

                                <div className="confirm-cancel-change-password-container">

                                    <div>
                                        <button className="confirm-change-password" onClick={handlePasswordChange}>Confim </button>
                                    </div>

                                    <div>
                                        <button className="cancel-change-password" onClick={handleClear}>Cancel </button>
                                    </div>
                                
                                </div>

                            </div>
                            
                        </div>
                        
                    </div>


                        

                        


                    </div>

                    
                    

                </div>

            

            </div>

            {successsToaster && 

                < CreatedSuccessfullToaster

                    successToasterTitle ='Password Changed'
                    successToasterMessage ='Your password has been changed successfully'

                />
            
            }

            {errorToaster && 

                <ToastError 
                    toasterError = 'Error Changing Password'
                />
            }

            
        
        </div>
    )
}

export default ProfileComponents