import React from "react";

import { useState } from "react";

import { Navigate, useNavigate } from "react-router-dom";

// CSS File 
import '../../../assets/css/Checklists.css'
import '../../../assets/css/ManageUsers.css'

// Api uril
import API_BASE_URL from "../../checklistmanagementcomponents/utils/apiConfig";

// components 
import UserInfoHeader from "../../UserInfoHeader";

//media
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

//toasters 
import CreatedSuccessfullToaster from "../../toasterscomponents/manageusersToaster/createdSuccessfullToaster";
import ToastError from "../../toasterscomponents/ToastError";




const CreateCompanyComponent = () => {

    const [companyCreatedSuccessfullyToaster, setCompanyCreatedSuccessfullyToaster] = useState(false);

    //error
    const [showTextEmptyNameError, setShowTextEmptyNameError] = useState(false);
    const [showErrorMessage, setErrorMessage] = useState(false);

    const [showDuplicateRegistrationError, setShowDuplicateRegistrationError] = useState(false);
    const [showDuplicateEmailError, setShowDuplicateEmailError] = useState(false);
    const [showDuplicatePhoneError, setShowDuplicatePhoneError] = useState(false);

    

    const [showCreateChecklistError, setShowCreateChecklistError] = useState(false); // New state for duplicate error

    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        id:"",
        registrationNumber: "",
        companyDepartment:"",
        companyName: "",
        email: "",
        phoneNumber: "",
        address: "",
        province: "",
        city: "",
        suburb: "",
        postalCode: ""
    });

    // Function to generate unique ID
    const generateUniqueId = (companyName) => {

    const firstThreeChars = companyName.slice(0, 3).toUpperCase(); // First 3 characters of company name
    const year = new Date().getFullYear().toString().slice(-2); // Last two digits of the year
    const month = ('0' + (new Date().getMonth() + 1)).slice(-2); // Month (zero padded)
    const day = ('0' + new Date().getDate()).slice(-2); // Day (zero padded)
    const randomDigits = Math.floor(10 + Math.random() * 90); // Random 2-digit number

    return `${firstThreeChars}${year}${month}${day}${randomDigits}`;
}

const [provinces] = useState([
    "Eastern Cape",
    "Free State",
    "Gauteng",
    "KwaZulu-Natal",
    "Limpopo",
    "Mpumalanga",
    "Northern Cape",
    "North West",
    "Western Cape"
]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

    };

    // clear form 
    const clearForm = () => {
        setFormData({
            id: "",
            registrationNumber: "",
            companyDepartment: "",
            companyName: "",
            email: "",
            phoneNumber: "",
            address: "",
            province: "",
            city: "",
            suburb: "",
            postalCode: ""
        });
        setShowTextEmptyNameError(false);
    };

    // Navigate back
    const handleNavigateBack = () => {

        navigate('/managerusers')

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        for (const key in formData) {
            if (formData[key] === "" && key !== "id") {
                setShowTextEmptyNameError(true);
                setShowCreateChecklistError(true);
                setErrorMessage('Error! Empty fields, please fill in all fields.');
                setTimeout(() => {
                    setShowCreateChecklistError(false);
                    setErrorMessage('');
                }, 3000);
                return;
            }
        }
    
        console.log("Form Data:", formData);
    
        try {
            const id = generateUniqueId(formData.companyName);
    
            const response = await fetch(`${API_BASE_URL}/submit_company`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, id }),
            });
    
            if (!response.ok) {
                const data = await response.json();
                console.error("Error creating company:", data.error);
    
                if (response.status === 400) {
                    if (data.error === 'Registration number already exists') {
                        setShowDuplicateRegistrationError(true);
                    } else if (data.error === 'Email already exists') {
                        setShowDuplicateEmailError(true);
                    } else if (data.error === 'Phone number already exists') {
                        setShowDuplicatePhoneError(true);
                    } else {
                        setShowCreateChecklistError(true);
                    }
                    setTimeout(() => {
                        setShowCreateChecklistError(false);
                        setShowDuplicateRegistrationError(false);
                        setShowDuplicateEmailError(false);
                        setShowDuplicatePhoneError(false);
                    }, 5000);
                } else {
                    setShowCreateChecklistError(true);
                    setTimeout(() => {
                        setShowCreateChecklistError(false);
                    }, 5000);
                }
            } else {
                console.log("Company created successfully");
                clearForm();
                setCompanyCreatedSuccessfullyToaster(true);
                setTimeout(() => {
                    setCompanyCreatedSuccessfullyToaster(false);
                    navigate('/viewusers');
                }, 2000);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            setShowCreateChecklistError(true);
            setErrorMessage('An unexpected error occurred');
            setTimeout(() => {
                setShowCreateChecklistError(false);
                setErrorMessage('');
            }, 3000);
        }
    };
    
    
    

    return (

        <div className="component-container">

            <div className="create-checklist-component-container">

                

                <div className="create-company-text-container">

                    <div>

                        <h1>Create Company </h1>

                        <p>Fill in from to register a company </p>

                    </div>

                    <div>
                        <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
                    </div>

                </div>

                <div className="navigate-back-button-main-container">

                    <div className="navigate-back-button-container">
                        <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                    </div>

                </div>

                <div className="create-company-form-container">

                    <form onSubmit={handleSubmit}>

                        <div className="create-company-form-body-container">

                            {/* Form body row */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p>Company Registration Number </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                    <input
                                            name="registrationNumber"
                                            className="create-company-form-input"
                                            placeholder="Enter your company registration"
                                            value={formData.registrationNumber}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>

                                <div className="create-company-form-element-container">

                                <div className="create-company-form-label-container">

                                    <label>
                                        <p>Company Name </p>
                                    </label>

                                </div>

                                <div className="create-company-form-input-container">

                                    <input
                                        name="companyName"
                                        className="create-company-form-input"
                                        placeholder="Enter your company name"
                                        value={formData.companyName}
                                        onChange={handleChange}
                                    />

                                    </div>

                                </div>

                            </div>

                            {/* Form body row */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p>Department </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                    <input
                                            name="companyDepartment"
                                            className="create-company-form-input"
                                            placeholder="Enter Department"
                                            value={formData.companyDepartment}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>

                            </div>

                            {/* Form body row */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p>Email Address </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                    <input
                                            name="email"
                                            className="create-company-form-input"
                                            placeholder="Enter company email address"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p>Phone Number </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                        <input
                                            name="phoneNumber"
                                            className="create-company-form-input"
                                            placeholder="Enter company phone number"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>
                            
                            </div>

                            {/* Form body row */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p>Address </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                        <input
                                            name="address"
                                            className="create-company-address-form-input"
                                            placeholder="Enter your address"
                                            value={formData.address}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>

                            </div>

                            {/* Form body row */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p> Province </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                    <select
                                            name="province"
                                            className="create-company-form-input"
                                            value={formData.province}
                                            onChange={handleChange}
                                        >
                                            <option value="">-- Select Province --</option>
                                            {provinces.map((province, index) => (
                                                <option key={index} value={province}>
                                                    {province}
                                                </option>
                                            ))}
                                        </select>

                                    </div>

                                </div>

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p> City </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                        <input
                                            name="city"
                                            className="create-company-form-input"
                                            placeholder="Enter your city"
                                            value={formData.city}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>                                

                            </div>

                            {/* Form body row */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p> Suburb </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                    <input
                                            name="suburb"
                                            className="create-company-form-input"
                                            placeholder="Enter your suburb"
                                            value={formData.suburb}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>

                                <div className="create-company-form-element-container"> 

                                    <div className="create-company-form-label-container">

                                        <label>
                                            <p> Postal Code </p>
                                        </label>

                                    </div>

                                    <div className="create-company-form-input-container">

                                        <input
                                            name="postalCode"
                                            className="create-company-form-input"
                                            placeholder="Enter your postal code"
                                            value={formData.postalCode}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>                                

                            </div>

                        </div>

                        {/* Error message */}
                        {showTextEmptyNameError && (
                            <p className="error-text">Error! Empty fields, please fill in all fields.</p>
                        )}

                        {/* Error message */}
                        {showDuplicateRegistrationError && (
                            <p className="error-text">Error! Registration number already exists.</p>
                        )}

                        {/* Error message */}
                        {showDuplicateEmailError && (
                            <p className="error-text">Error! Email already exists'.</p>
                        )}

                        {/* Error message */}
                        {showDuplicatePhoneError && (
                            <p className="error-text">Error! Phone number already exists.</p>
                        )}

                        
                        {/* */}
                        <div >

                            <div className="create-company-form-text-container">
                                <p>By clicking create company button, company will be created.</p>
                            </div>

                            <div className="create-company-form-button-container">

                                
                                <div>
                                    <button type="button" className="create-company-form-cancel-button" onClick={clearForm}>Cancel</button>
                                </div>

                                <div>
                                    <button type="submit" className="create-company-form-create-button">Create Company</button>
                                </div>


                            </div>

                        </div>

                    </form>

                </div>
                

            </div>

            {showCreateChecklistError && 
                <ToastError 
                    toasterError='Error Creating company'
                />
            }

            {companyCreatedSuccessfullyToaster &&
            
                <CreatedSuccessfullToaster 
                    successToasterTitle="Company Created" 
                    successToasterMessage="Company created successfully."
                />
            }

        </div>
    )
}


export default CreateCompanyComponent