import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

//api url 
import API_BASE_URL from "../../checklistmanagementcomponents/utils/apiConfig";

// components 
import UserInfoHeader from "../../UserInfoHeader";

// component- toaster 
import CreatedSuccessfullToaster from "../../toasterscomponents/manageusersToaster/createdSuccessfullToaster";

// media
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

import ToastError from "../../toasterscomponents/ToastError";



const CreateUserComponent = () => {

    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState(""); 

    const [showCreateChecklistError, setShowCreateChecklistError] = useState(false); // New state for duplicate error
    
    // Error Validation 
    const [showTextEmptyNameError, setShowTextEmptyNameError] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [ShowPasswordValidationError, setShowPasswordValidationError] = useState(false);

    const [ShowDuplicateIdError, setShowDuplicateIdError] = useState(false) //id
    const [showDuplicateEmailError, setShowDuplicateEmailError] = useState(false); // email
    const [showDuplicatePhoneError, setShowDuplicatePhoneError] = useState(false); // phone

    const [companyCreatedSuccessfullyToaster, setCompanyCreatedSuccessfullyToaster] = useState(false);
    const [showEmailValidationError, setShowEmailValidationError] = useState(false)

    const navigate = useNavigate();

    const [createUserFormData, setCreateUserFormData] = useState({

        id: "",
        companyId: '',
        departmentId: '',
        userIdNo: '',
        userName: '',
        userSurname: '',
        userEmail: '',
        userPhone: '',
        userRole: '',
        userPassword: '',

        userConfirmPassword: ''
        
    });

    // Function to generate unique ID
    const generateUniqueId = (userName) => {

        const firstThreeChars = userName.slice(0, 3).toUpperCase(); // First 3 characters of company name
        const year = new Date().getFullYear().toString().slice(-2); // Last two digits of the year
        const month = ('0' + (new Date().getMonth() + 1)).slice(-2); // Month (zero padded)
        const day = ('0' + new Date().getDate()).slice(-2); // Day (zero padded)
        const randomDigits = Math.floor(10 + Math.random() * 90); // Random 2-digit number
    
        return `${firstThreeChars}${year}${month}${day}${randomDigits}`;
    }

    useEffect(() => {
        // Fetch companies data from the backend
        fetch('/api/companies')
        
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    console.log('Fetched companies:', data); // Add log
                    setCompanies(data);
                } else {
                    console.error('Invalid data format for companies:', data);
                }
            })
            .catch(error => console.error('Error fetching companies:', error));

        // Fetch departments data from the backend
        fetch('/api/departments')
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    console.log('Fetched departments:', data); // Add log
                    setDepartments(data);
                } else {
                    console.error('Invalid data format for departments:', data);
                }
            })
            .catch(error => console.error('Error fetching departments:', error));
    }, []);

    // Filter departments based on selected company
    const filteredDepartments = departments.filter(department => department.companyId === selectedCompany);

    //
    const clearForm = () => {

        setCreateUserFormData({
            id: "",
            companyId: '',
            departmentId: '',
            userIdNo: '',
            userName: '',
            userSurname: '',
            userEmail: '',
            userPhone: '',
            userPassword: '',
            userConfirmPassword: '',
            userRole: ''

        }) ; 
        
        setShowTextEmptyNameError(false)
        setShowPasswordError(false);
        setShowPasswordValidationError(false);
        setShowDuplicateIdError(false)
        setShowDuplicateIdError(false);
        setShowDuplicateEmailError(false);

    }

    const handleNavigateBack = () => {

        navigate('/managerusers')

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        if (
          !selectedCompany ||
          !selectedDepartment ||
          !createUserFormData.userIdNo ||
          !createUserFormData.userName ||
          !createUserFormData.userSurname ||
          !createUserFormData.userEmail ||
          !createUserFormData.userPhone ||
          !createUserFormData.userPassword ||
          !createUserFormData.userConfirmPassword ||
          !createUserFormData.userRole
        ) {
          setShowTextEmptyNameError(true);
          setShowCreateChecklistError(true);
      
          setTimeout(() => {
            setShowCreateChecklistError(false);
          }, 5000);
      
          return;
        }
      
        if (createUserFormData.userPassword !== createUserFormData.userConfirmPassword) {
          setShowPasswordError(true);
          setShowCreateChecklistError(true);
      
          setTimeout(() => {
            setShowCreateChecklistError(false);
          }, 5000);
      
          return;
        }
      
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(createUserFormData.userPassword)) {
          setShowPasswordValidationError(true);
          setShowCreateChecklistError(true);
      
          setTimeout(() => {
            setShowCreateChecklistError(false);
          }, 5000);
      
          return;
        }

        // Check email validity
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(createUserFormData.userEmail)) {
        setShowEmailValidationError(true); // Ensure you have this state to show the error
        setShowCreateChecklistError(true);
    
        setTimeout(() => {
            setShowCreateChecklistError(false);
        }, 5000);
    
        return;
    }
    
      
        const uniqueId = generateUniqueId(createUserFormData.userName);
      
        const formDataWithId = {
          ...createUserFormData,
          id: uniqueId,
          companyId: selectedCompany,
          departmentId: selectedDepartment,
        };
      
        console.log("Form Data:", formDataWithId);
      
        try {
            const response = await fetch(`${API_BASE_URL}/api/create_user`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formDataWithId),
            });
        
            const data = await response.json();
        
            if (!response.ok) {

              if (response.status === 400) {

                if (data.error.includes('ID number already exists')) {

                  setShowDuplicateIdError(true);

                } else if (data.error.includes('Email already assigned to a user')) {
                    //email address duplicate error
                  setShowDuplicateEmailError(true);

                } else if (data.error.includes('Phone Number already assigned to a user')) {
                    //Phone number duplicate error 
                    setShowDuplicatePhoneError(true);

                }else {
                  setShowCreateChecklistError(true);
                }

              } else {

                setShowCreateChecklistError(true);

              }
        
              setTimeout(() => {
                setShowCreateChecklistError(false);
                
              }, 5000);
        
              return;
            }
        
            console.log("User created successfully");
            clearForm();
            setCompanyCreatedSuccessfullyToaster(true);
        
            setTimeout(() => {
              setCompanyCreatedSuccessfullyToaster(false);
              navigate('/assignusers');
            }, 2000);
            
          } catch (error) {
            console.error("Error creating user:", error);
            setShowCreateChecklistError(true);
        
            setTimeout(() => {
              setShowCreateChecklistError(false);
            }, 5000);
          }
      };
      

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setCreateUserFormData(prevData => ({ ...prevData, [name]: value }));
    };

    return (
        <div className="component-container">
            <div className="create-checklist-component-container">

                <div className="create-company-text-container">
                    <div>
                        <h1>Create User</h1>

                        <p>Fill in form to create a user.</p>

                    </div>
                    <div>
                        <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
                    </div>
                </div>

                <div className="navigate-back-button-main-container">

                    <div className="navigate-back-button-container">
                        <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                    </div>

                </div>

                <div className="create-company-form-container">

                    <form onSubmit={handleSubmit}>

                        <div className="create-company-form-body-container">
                            
                            {/* Other form body rows... */}
                            <div className="create-company-form-body-row-password-container">

                                <div>
                                    <h3>User Details </h3>
                                </div>
 
                                <div>
                                    <p>Fill user details </p>
                                </div>

                            </div>

                            {/* Other form body rows... */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container">
                                    
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>ID Number</p>
                                        </label>
                                    </div>
                                    
                                    <div className="create-company-form-input-container">
                                        <input
                                            name="userIdNo"
                                            className="create-company-form-input"
                                            placeholder="Enter your ID Number"
                                            value={createUserFormData.userIdNo}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                </div>
                            </div>

                            {/* Other form body rows... */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container">
                                    
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>Name</p>
                                        </label>
                                    </div>

                                    <div className="create-company-form-input-container">

                                        <input
                                            name="userName"
                                            className="create-company-form-input"
                                            placeholder="Enter your name"
                                            value={createUserFormData.userName}
                                            onChange={handleInputChange}
                                        />

                                    </div>

                                </div>

                                <div className="create-company-form-element-container">
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>Surname</p>
                                        </label>
                                    </div>
                                    <div className="create-company-form-input-container">
                                        <input
                                            name="userSurname"
                                            className="create-company-form-input"
                                            placeholder="Enter your surname"
                                            value={createUserFormData.userSurname}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Other form body rows... */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container">
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>Email</p>
                                        </label>
                                    </div>
                                    <div className="create-company-form-input-container">
                                        <input
                                            name="userEmail"
                                            className="create-company-form-input"
                                            placeholder="Enter your email address"
                                            value={createUserFormData.userEmail}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="create-company-form-element-container">
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>Phone</p>
                                        </label>
                                    </div>
                                    <div className="create-company-form-input-container">
                                        <input
                                            name="userPhone"
                                            className="create-company-form-input"
                                            placeholder="Enter your phone number"
                                            value={createUserFormData.userPhone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Other form body rows... */}
                            <div className="create-company-form-body-row-password-container">

                                <div>
                                    <h3>User Company Details  </h3>
                                </div>
 
                                <div>
                                    <p>Fill in detials of where us </p>
                                </div>

                            </div>
                            {/* Form body row */}
                            <div className="create-company-form-body-row-container">
                                
                                <div className="create-company-form-element-container">
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>Company</p>
                                        </label>
                                    </div>
                                    <div className="create-company-form-input-container">
                                        <select 
                                            value={selectedCompany} 
                                            onChange={e => setSelectedCompany(e.target.value)}>
                                            <option value="">-- Select Company Name --</option>
                                            {companies.map(company => (
                                                <option key={company.id} value={company.id}>
                                                    {company.companyName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="create-company-form-element-container">

                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>Department</p>
                                        </label>
                                    </div>

                                    <div className="create-company-form-input-container">
                                        
                                        <select
                                            value={selectedDepartment} 
                                            onChange={e => setSelectedDepartment(e.target.value)}
                                        >
                                            <option value="">-- Select Department --</option>
                                                {filteredDepartments.map(department => (
                                                    <option key={department.id} value={department.id}>
                                                        {department.departmentName}
                                                    </option>
                                                ))}
                                        </select>

                                    </div>

                                </div>
                            </div>

                            {/* Other form body rows... */}
                            <div className="create-company-form-body-row-password-container">

                                <div>
                                    <h3>User Role </h3>
                                </div>
 
                                <div>
                                    <p>User role and priviledges when using application </p>
                                </div>

                            </div>

                            {/* Other form body rows... */}
                            <div className="create-user-roles-form-body-row-container">

                                <div className="create-company-form-element-container">
                                    
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>User Role</p>
                                        </label>
                                    </div>
                                    
                                    <div className="create-company-form-input-container">
                                        <select
                                            name="userRole"
                                            value={createUserFormData.userRole}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">-- Select User Role --</option>

                                            <option value="super admin">super admin</option>
                                            <option value="admin">admin</option>
                                            <option value="user">user</option>

                                        </select>
                                    </div>

                                </div>

                                <div className="create-user-roles-priviledges-detail-main-container">

                                    <div className="create-user-roles-priviledges-detail-container">

                                        <div>
                                            <h3> User Priviledges</h3>
                                        </div>

                                        <div>

                                            {/* Super Admin */}
                                            <div>
                                                <h4>Super Admin </h4>

                                                <p>
                                                    - User Management ( Create, Edit & delete Comapny and users )
                                                    <br />
                                                    -  Checklist Management (Create , Edit and delete checklists)
                                                </p>
                                            </div>

                                            {/* Admin */}
                                            <div>
                                                <h4>Admin </h4>

                                                <p>
                                                    -  Checklist Management (Create , Fillin , Edit and delete checklists)
                                                </p>
                                            </div>

                                            {/* Admin */}
                                            <div>
                                                <h4>User </h4>

                                                <p>
                                                    -  Checklist Management (Fill In checklists)
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                            </div>

                             

                            {/* Other form body rows... */}
                            <div className="create-company-form-body-row-password-container">

                                <div>
                                    <h3>User Password</h3>
                                </div>
                                <div>

                                    <p>Create a password for the user</p>

                                    <p className="important-info-text">*Password must contain at least one special character, one letter and one number</p>
                                
                                </div>
                            </div>

                            {/* Other form body rows... */}
                            <div className="create-company-form-body-row-container">

                                <div className="create-company-form-element-container">
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>Password</p>
                                        </label>
                                    </div>
                                    <div className="create-company-form-input-container">
                                        <input
                                            type="password"
                                            name="userPassword"
                                            className="create-company-form-input"
                                            placeholder="Enter your password"
                                            value={createUserFormData.userPassword}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                </div>

                                <div className="create-company-form-element-container">
                                    <div className="create-company-form-label-container">
                                        <label>
                                            <p>Confirm Password</p>
                                        </label>
                                    </div>
                                    <div className="create-company-form-input-container">
                                        <input
                                            type="password"
                                            name="userConfirmPassword"
                                            className="create-company-form-input"
                                            placeholder="Confirm your password"
                                            value={createUserFormData.userConfirmPassword}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                </div>
                            </div>

                            
                        </div>

                        {ShowDuplicateIdError && (
                            <p className="error-text">Error! ID Number already exists.</p>
                        )}

                        {showDuplicateEmailError && (
    <p className="error-text">Error! Email already belongs to another user.</p>
  )}

  {showDuplicatePhoneError && (
    <p className="error-text">Error! Phone number already belongs to another user.</p>

  )}

  {showEmailValidationError && (

    <p className="error-text">Error! Please enter a valid email address.</p>

  )}

                        {/* Error message - Empry Fields  */}
                        {showTextEmptyNameError && (
                            <p className="error-text">Error! Empty fields, please fill in all fields.</p>
                        )}

                        {/* Error Message - Password Mismatch */}
                        {showPasswordError && (
                            <p className="error-text">Error! Password & confirm password dont match.</p>
                        )}

                        {/* Check if password meets criteria (at least one special character, one letter, and one number) */}
                        {ShowPasswordValidationError && (
                            <p className="error-text">Error! Password must contain at least one special character, one letter and one number.</p>
                        )}

                        <div>
                            <div className="create-company-form-text-container">
                                <p>By clicking the create user button, the user will be created.</p>
                            </div>

                            <div className="create-company-form-button-container">
                                <div>
                                    <button type="button" className="create-company-form-cancel-button" onClick={clearForm}>Cancel</button>
                                </div>
                                <div>
                                    <button type="submit" className="create-company-form-create-button" >Create User</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {showCreateChecklistError && 
                <ToastError 
                    toasterError='Error Creating User'
                />
            }

            {companyCreatedSuccessfullyToaster && 

                <CreatedSuccessfullToaster 
                    successToasterTitle="User Created" 
                    successToasterMessage="User created successfully."
                />
            
            }
        </div>
    );
};

export default CreateUserComponent;
