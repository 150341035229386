/* Component - Edit SHE File Items.
    
    -   This component allows the user to be edit and add SHE File components.  

*/

// React Components 

import React, { useState ,useEffect } from "react";

// Component styles  

import '../styles/shefilestyles.css'; // css file
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; // image KBC Logo

// Toasters 

import ToastError from "../../toasterscomponents/ToastError"; // Toaster appears when theres errors

// icons 

import { CiImageOn } from "react-icons/ci"; //
import { MdDeleteOutline } from "react-icons/md"; //


const EditSHEFileItems = ({ selectedTable, columns, formData, setFormData, handleInputChange, handleSubmit, handleBackClick, uniqueID, riskRatingOptions , categoryOptions, observationOutcomeOptions, showTextEmptyNameError}) => {
    
    // Retrieve user data from session storage
    const user = JSON.parse(sessionStorage.getItem('user'));

    // -------------- State Management ------------------

    const [file, setFile] = useState(null); // State to hold selected file object
    const [filePreview, setFilePreview] = useState(""); // State to hold file preview URL

    const [formattedDateTime, setFormattedDateTime] = useState("");
    

    // errors
    const [showAssignUserError, setShowAssignUserError] = useState(false);
    const [showLargeFileErrorError, setShowLargeFileErrorError] = useState(false);
    
    
    // --------------- Component Functions ---------------


    // Function to reset form data
    
    const handleClear = () => {

        setFormData({}); // Reset formData to an empty object
        setFile(null); // Clear selected file
        setFilePreview(""); // Clear file preview
        setShowAssignUserError(false)
        setShowLargeFileErrorError(false)

    };

    // FUnction to remove a file that was added

    const handleRemoveFile = () => {

        setFile(null); // Clear selected file
        setFilePreview(""); // Clear file preview

    };

    // Function to handle file input change
    const handleFileChange = (column, selectedFile) => {
        
        if (selectedFile) {

        // Check file size
        const fileSize = selectedFile.size / 1024 / 1024; // in MB
        const fileSizeLimit = 1; // limits file size to 1mb

        if (fileSize > fileSizeLimit) {
            setShowLargeFileErrorError(true);
            setShowAssignUserError(true);

            // Automatically hide the error toaster after 3 seconds
            setTimeout(() => {
                setShowAssignUserError(false);
            }, 2000);

            return;
        } else {
            setShowLargeFileErrorError(false);
            setShowAssignUserError(false);
        }
            setFile(selectedFile); // Set selected file
            handleInputChange(column, selectedFile); // Pass the file object to handleInputChange
        } else {
            setFile(null); // Clear selected file if no file selected
            setFilePreview(""); // Clear file preview
        }
    };
    
    // Function to handle click on file name link

    const handleFileNameClick = () => {
        if (filePreview) {
            setFilePreview(""); // Close file preview if it's open
        } else if (file) {
            setFilePreview(URL.createObjectURL(file)); // Set preview URL when file name link is clicked
        }
    };

    // function for date and time format

    const formatDateTime = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${day} ${month} ${year} ${hours}:${minutes}`;
    };

    // ---------------- Use Effects ----------------------

    useEffect(() => {
        const updateDateTime = () => {
            setFormattedDateTime(formatDateTime(new Date()));
        };

        // Update the date and time immediately when the component mounts
        updateDateTime();

        // Set an interval to update the date and time every minute
        const interval = setInterval(updateDateTime, 60000);

        // Cleanup the interval when the component unmounts
        return () => clearInterval(interval);

    }, []);
    
    

    return (

        <div className="edit-she-file-item-main-container">

            <div className="edit-she-file-container">

                <div className="edit-she-file-elements-container">

                    <div className="edit-she-file-in-back-button-time-container">

                        <div className='edit-she-file-back-button-container'>

                            <div>
                                <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
                            </div>

                            <div>
                                <button type="button" onClick={handleBackClick} className="user-fill-in-back-button">Cancel</button>
                            </div>

                        </div>

                        <div className='user-fill-date-container'>

                            <div>
                                <p>{formattedDateTime}</p>
                            </div>
                            
                        </div>

            
                    </div>

                    

                    <div className="edit-she-file-uid-container">

                        <div>
                            <h2>SHE file Name : <span>{selectedTable}</span></h2>
                        </div>

                        <div className='edit-she-file-uid-userid-container'>

                            <div className='user-fillin-checklist-id-container'>
                                <div>
                                    <p>Checklist ID : </p>
                                </div>

                                <div>
                                    <p>{uniqueID}</p>
                                </div>
                            
                            </div>

                            <div className='user-fillin-checklist-id-container'>
                                <div>
                                    <p>User ID : </p>
                                </div>

                                <div>
                                    <p>{user.id}</p>
                                </div>
                            
                            </div>
                            
                            
                        </div>
                    </div>

                    <div className="edit-she-file-main-rows-container">

                        {columns.map((column) => (
                            
                            column !== 'id' && column !== 'userId' && column !== 'date_submitted' && (

                                <div className="she-file-items-container-container">

                                    <div key={column} className="she-file-items-form-elements-container">

                                    <div>
                                        <label>{column}</label>
                                    </div>

                                    <div>
                                        {column === 'Date' ? (
                                            <input
                                                className='user-fill-in-input-field'
                                                type="date"
                                                value={formData[column] || ""}
                                                onChange={(e) => handleInputChange(column, e.target.value)}
                                                placeholder={`Please enter ${column}`}
                                            />
                                        ) : column === 'Photo' ? (

                                            <>
                                                <input
                                                    className='user-fill-in-input-photo-field'
                                                    type="file"
                                                    onChange={(e) => handleFileChange(column, e.target.files[0])}
                                                    placeholder={`Please enter ${column}`}
                                                />

                                                <div className="user-fill-in-input-photo-container">

                                                    <div className="user-fill-in-input-photo-file">
                                                        {file && (
                                                            <div className="user-fill-in-input-photo-file-container">
                                                                <a
                                                                    className="file-link"
                                                                    href="#!"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleFileNameClick();
                                                                    }}
                                                                >
                                                                    <CiImageOn /> {file.name}
                                                                </a>

                                                                <MdDeleteOutline type="button" onClick={handleRemoveFile}/>
                                                            </div>
                                                        )}
                                                        {filePreview && (
                                                            <div className="image-preview-container">
                                                                <div>
                                                                    <img src={filePreview} alt="Preview" className="file-preview-image" />
                                                                </div>
                                                                
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>

                                        ) : column === 'RiskRating' ? (

                                            <div className="user-fillin-checklist-risk-rating">

                                                {riskRatingOptions.map((option) => (
                                                <label key={option} className="user-fillin-checklist-risk-rating-input">
                                                    <input
                                                    
                                                        type="radio"
                                                        name={column}
                                                        value={option}
                                                        checked={formData[column] === option}
                                                        onChange={(e) => handleInputChange(column, e.target.value)}
                                                    />
                                                    {option}
                                                </label>
                                                ))}
                                            </div>

                                        ) : column === 'Category' ? (

                                            <div className="user-fillin-checklist-risk-rating">

                                                {categoryOptions.map((option) => (

                                                <label key={option} className="user-fillin-checklist-risk-rating-input">
                                                    
                                                    <input
                                                    
                                                        type="radio"
                                                        name={column}
                                                        value={option}
                                                        checked={formData[column] === option}
                                                        onChange={(e) => handleInputChange(column, e.target.value)}
                                                    
                                                    />

                                                    {option}
                                                    
                                                </label>

                                                ))}

                                            </div>

                                        ) : column === 'ObservationOutcome' ? (

                                            <div className="user-fillin-checklist-risk-rating">
                                                {observationOutcomeOptions.map((option) => (
                                                <label key={option} className="user-fillin-checklist-risk-rating-input">
                                                    <input
                                                    
                                                        type="radio"
                                                        name={column}
                                                        value={option}
                                                        checked={formData[column] === option}
                                                        onChange={(e) => handleInputChange(column, e.target.value)}
                                                    />
                                                    {option}
                                                </label>
                                                ))}
                                            </div>

                                        ) : (

                                            <input
                                                className='user-fill-in-input-field'
                                                type="text"
                                                value={formData[column] || ""}
                                                onChange={(e) => handleInputChange(column, e.target.value)}
                                                placeholder={`Please enter ${column}`}
                                            />
                                            
                                        )}
                                    </div>

                                    
                                </div>

                                
                                </div>
                            )
                        ))}

                    </div>

                    {showTextEmptyNameError && (
                        <p className="error-text">Error! Empty fields, please fill in all fields.</p>
                    )}

                    {showLargeFileErrorError && (
                        <p className="error-text">Error! File too large, file exceeds 2mb. </p>
                    )}

                    <div className="user-fill-in-submit-clear-button-container">
                        <div>
                            <p>By Clicking submit you agree that all the information is factual</p>
                        </div>
                        <div className="user-fill-in-submit-back-button-container">
                            <button onClick={handleSubmit} className="user-fill-in-submit-button"> Submit</button>
                            <button type='button' onClick={handleClear} className="user-fill-in-back-button"> Clear</button>
                        </div>
                    </div>
                </div>
            </div>

            {showAssignUserError && 
                <ToastError 
                    toasterError='Error '
                />
            }
        </div>
    );
};

export default EditSHEFileItems;
