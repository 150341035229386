import React from "react";
import UserInfoHeader from "../UserInfoHeader";

import { Link } from "react-router-dom";

import { FaRegBuilding } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";


import { MdOutlineConstruction } from "react-icons/md";

import { CiLink } from "react-icons/ci";



const ManageUsers = () => {

    const user = JSON.parse(sessionStorage.getItem('user'));

    return (

        <div className="component-container">

            <div className="checklist-component-container">

                <div className="checklist-text-container">

                    <div>
                        <h1>User Management</h1>
                    </div>

                    <div>
                        <p> Create users, manage users priviledges and remove users </p>
                    </div>

                </div>

                {/* Navigations to applicaiton pages */}
                <div className="checklist-feature-main-container">

                    {/* Create Checklist */}
                    {user.userRole === 'super admin'  && (
                    <Link className="link" to='/createcompany'>

                    <div className='application-feature-container'>

                        <div className="feature-img-container">
                            <FaRegBuilding className="feature-img"/>
                        </div>

                        <div className="feature-name-container">

                            <h4>Create Company</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                Create a checklist tailored to your requirements
                            </p>

                        </div>

                    </div>
                    
                    </Link>
                    )}

                    {/* Fill In Checklist  */}
                    {user.userRole === 'super admin'  && (
                    <Link className="link" to='/createuser'>

                    {/* Fill Checklist */}
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <FaUserPlus className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>Create User</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                To create a new user.
                            </p>

                        </div>

                    </div>
                    
                    </Link>
                    )}

                    {/* View Form */}
                    <Link className="link" to='/assignusers'>

                    {/* View form */}
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <CiLink className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>Assign</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                To assign a user or a company to a checklist.
                            </p>

                        </div>

                    </div>
                    
                    </Link>

                    

                    

                    {/* View Form */}
                    <Link className="link" to='/viewusers'>

                    {/* View form */}
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <HiMagnifyingGlass className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>View</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                To view, edit or remove users & companies
                            </p>

                        </div>

                    </div>
                    
                    </Link>


                </div>



            </div>

        </div>
    )
}

export default ManageUsers