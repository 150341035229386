import React from "react";
import { Link } from "react-router-dom";

// CSS File 
import '../assets/css/header.css'; 

    /* UserInfoHeader Component consists of the following functions

        - user greeting with user name. 
        - user initials and roles 
        - option to view profile to access options to edit useer details

    */

    const UserInfoHeader = () => {

        // Greeting function is used to greet user according to the time of day for example morning is good morinng
        // Retrieve user data from session storage
        // Retrieve user data from session storage
        const user = JSON.parse(sessionStorage.getItem('user'));

        // Function to get initials from username and surname
        // Function to get initials from username and surname
    const getInitials = (userName, userSurname) => {
        if (!userName || !userSurname) return ''; // Return empty string if names are not available
        const initials = userName.charAt(0) + userSurname.charAt(0);
        return initials.toUpperCase(); // Convert to uppercase
    };

        const greeting = () => {

            const hour = new Date().getHours();

            if (hour >= 0 && hour < 12) {

                return "Good Morning"; 

            } else if (hour >= 12 && hour < 15) {

                return "Good Day";

            } else if (hour >= 15 && hour < 18) {

                return "Good Afternoon";

            } else {

                return "Good Evening";

            }


        }

        return (

            <div className="user-info-header-main-container">

                <div className="user-info-header-container">

                    <div className="user-greeting-container">

                        <h1>{greeting() } {/* User greeting  */} <span style={{fontWeight: "535", color:'#0088CB', fontSize:'35px'}}> {user.userName} </span></h1>
                        
                    </div>

                    <div className="user-role-container">

                        

        

                        {/*  */}
                        <div className="user-role-initials-container" title={`${user.userName} ${user.userSurname}`}>
                            
                            <Link className="link" to='/profile'>

                                <div className="user-role-initials">
                                    <p>{getInitials(user.userName, user.userSurname)}</p>
                                </div>

                            </Link>

                        </div>
                        
                    </div>

                </div>

            </div>

        );

    }

export default UserInfoHeader