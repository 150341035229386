import React from "react";
import { useState } from "react";

import { NavLink, useNavigate, useLocation } from "react-router-dom";

// icons
import { GoGraph } from "react-icons/go";
import { IoFileTrayFullOutline } from "react-icons/io5";
import { IoPeopleOutline } from "react-icons/io5";
import { CiUser, CiFolderOn } from "react-icons/ci";
import { IoIosLogOut } from "react-icons/io";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { RxHamburgerMenu } from "react-icons/rx";

import whiteKbcLogo from '../assets/images/branding/white-kbc-logo.png';
import blueKbcLogo from '../assets/images/branding/blue-kbc-logo.png';


import '../assets/css/header.css';

import CreatedSuccessfullToaster from "./toasterscomponents/manageusersToaster/createdSuccessfullToaster";

const Header = ({ isAuthenticated, onAuthentication , isCollapsed, toggleCollapse }) => {

    const [showLogoutSuccessfullyToaster, setShowLogoutSuccessfullyToaster] = useState(false);

    

    const user = JSON.parse(sessionStorage.getItem('user'));

    const navigate = useNavigate();
    const location = useLocation();

    

    const handleLogout = () => {
        

        setShowLogoutSuccessfullyToaster(true);

        setTimeout(() => {
            setShowLogoutSuccessfullyToaster(false);

            sessionStorage.removeItem('isAuthenticated'); // Clear session storage
            onAuthentication(false); // Update authentication state
            navigate('/'); // Redirect to the home page or login page

            console.log('logged out');
        }, 1000);

        
    };

     // Check if we're on the homepage
     const isHomePage = location.pathname === '/';

     // Do not render the header if we're on the homepage
     if (isHomePage) {
         return null;
     }

    return (
        <div className={`header-container ${isCollapsed ? 'collapsed' : ''}`}>

            

            <div className="toggle-button-container" onClick={toggleCollapse}>
                {isCollapsed ? < RxHamburgerMenu /> : <IoIosArrowBack />}
            </div>

            

            
            {/* KBC Application Logo */}
            <div className="side-menu-logo-container">
                <NavLink to='/dashboard'>
                    <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
                </NavLink>
            </div>

            <div className="side-menu-container">

                <NavLink to='/dashboard' className="link" activeClassName="active">
                    <div className="side-menu-nav-container" title="Dashboard">
                        <div className="nav-menu-icon-container">
                            <GoGraph className="nav-menu-icon" />
                        </div>
                        <div className="nav-menu-link-container">
                            <p>Dashboard</p>
                        </div>
                    </div>
                </NavLink>

                <NavLink to='/checklistmanagement' className="link" activeClassName="active">

                    <div className="side-menu-nav-container" title="To create, edit, view & delete forms">
                        
                        <div className="nav-menu-icon-container">
                            <IoFileTrayFullOutline  className="nav-menu-icon" />
                        </div>

                        <div className="nav-menu-link-container">
                            <p>SHE File</p>
                        </div>

                    </div>

                </NavLink>

                {user.userRole !== 'user' && (
                    <NavLink to='/managerusers' className="link" activeClassName="active">
                        <div className="side-menu-nav-container" title="To manage user privileges, add new users and remove users.">
                            <div className="nav-menu-icon-container">
                                <IoPeopleOutline className="nav-menu-icon" />
                            </div>
                            <div className="nav-menu-link-container">
                                <p>Users</p>
                            </div>
                        </div>
                    </NavLink>
                )}

                {isAuthenticated && (
                

                    <div className="side-menu-nav-container link" activeClassName="active" title="To logout" onClick={handleLogout}>
                        <div className="nav-menu-icon-container">
                            <IoIosLogOut className="nav-menu-icon" />
                        </div>
                        <div className="nav-menu-link-container">
                            <p>Logout</p>
                        </div>
                    </div>
                )
                }
                
            </div>

            {showLogoutSuccessfullyToaster && 

                <CreatedSuccessfullToaster 
                    successToasterTitle="Logout" 
                    successToasterMessage="Successfully logged out. One moment while we redirect you to the login page"
                />
            
            }

        </div>
    );
};

export default Header;
