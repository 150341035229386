/*  */


/*-------------------------------------------------------
                    Add Form Section 
--------------------------------------------------------*/

export const addFormSection = (formSections, setFormSections, minimizedSections, setMinimizedSections, activeSectionIndex, setActiveSectionIndex, section) => {

    /* --------------- Radio Section ----------------- */
        if (section.name === 'RiskRating') {

            section.options = ['Low Risk', 'Medium Risk', 'High Risk']; // 

            // Update the last added section
            setFormSections(prevFormSections => {
                
                const lastSectionIndex = prevFormSections.length - 1;

                return prevFormSections.map((s, i) => {
                    if (i === lastSectionIndex) {
                        return { ...s, question: 'RiskRating', disabled: true };
                    }
                    return s;

                });
            });

        }

        if (section.name === 'Category') {

            section.options = ['Chemicals','House Keeping', 'Knowledge / Behaviour','People', 'PPE','Procedure','Tools / Equipment']; // 

            // Update the last added section
            setFormSections(prevFormSections => {
                
                const lastSectionIndex = prevFormSections.length - 1;

                return prevFormSections.map((s, i) => {
                    if (i === lastSectionIndex) {
                        return { ...s, question: 'Category', disabled: true };
                    }
                    return s;

                });
            });

        }

        if (section.name === 'ObservationOutcome') {

            section.options = ['Meets Expectations', 'Improvement Required' , 'Not Applicable']; // 

            // Update the last added section
            setFormSections(prevFormSections => {
                
                const lastSectionIndex = prevFormSections.length - 1;

                return prevFormSections.map((s, i) => {
                    if (i === lastSectionIndex) {
                        return { ...s, question: 'ObservationOutcome', disabled: true };
                    }
                    return s;

                });
            });

        }

    section.isRequired = false; // Set default value for isRequired

    // If there's an active section, insert the new section after it
    if (activeSectionIndex !== null) {

        const updatedFormSections = [...formSections];
        updatedFormSections.splice(activeSectionIndex + 1, 0, section);
        setFormSections(updatedFormSections);

    } else {
        // If no active section, add at the end
        setFormSections([...formSections, section]);

    }

    setMinimizedSections([...minimizedSections, false]);

    /* ------------------------------------------------
                Predifiend form sections
        ---------------------------------------------- */

    // If the added section is for name, update the question input value
    if (section.inputField === 'name') {

        // Update the last added section
        setFormSections(prevFormSections => {

            const lastSectionIndex = prevFormSections.length - 1;

            return prevFormSections.map((s, i) => {

                if (i === lastSectionIndex) {
                    return { ...s, question: 'Name', disabled: true };
                }
                return s;

            });
        });
    }

    // If the added section is for date, update the question input value
    if (section.inputField === 'date') {

        // Update the last added section
        setFormSections(prevFormSections => {
            
            const lastSectionIndex = prevFormSections.length - 1;

            return prevFormSections.map((s, i) => {
                if (i === lastSectionIndex) {
                    return { ...s, question: 'Date', disabled: true };
                }
                return s;

            });
        });
    }

    // If the added section is for Phone, update the question input value
    if (section.inputField === 'phone') {

        // Update the last added section
        setFormSections(prevFormSections => {
            
            const lastSectionIndex = prevFormSections.length - 1;

            return prevFormSections.map((s, i) => {
                
                if (i === lastSectionIndex) {
                    return { ...s, question: 'Phone', disabled: true };
                }
                return s;

            });
        });
    }

    // If the added section is for Phone, update the question input value
    /*if (section.inputField === 'address') {

        // Update the last added section
        setFormSections(prevFormSections => {
            
            const lastSectionIndex = prevFormSections.length - 1;

            return prevFormSections.map((s, i) => {
                if (i === lastSectionIndex) {
                    return { ...s, question: 'Address', disabled: true };
                }
                return s;

            });
        });
    }
        */

    // If the added section is for Address, update the question input value
    if (section.inputField === 'address') {

        // Update the last added section
        setFormSections(prevFormSections => {
            
            const lastSectionIndex = prevFormSections.length - 1;

            return prevFormSections.map((s, i) => {
                if (i === lastSectionIndex) {
                    return { ...s, question: 'Address', disabled: true };
                }
                return s;

            });
        });
    }

    // Check if the section type is custom
    //if (section.inputField === 'custom') {
        
        // Set default properties for the custom form field
       // section.isRequired = false;
       // section.question = ''; // Set question to empty string
       // section.disabled = true; // Disable the question input field

        
    //}

    // If the added section is for Address, update the question input value
    if (section.inputField === 'custom') {

        // Update the last added section
        setFormSections(prevFormSections => {
            
            const lastSectionIndex = prevFormSections.length - 1;

            return prevFormSections.map((s, i) => {
                if (i === lastSectionIndex) {
                    return { ...s, question: 'Custom', disabled: true };
                }
                return s;

            });
        });
    }

    // If the added section is for Address, update the question input value
    if (section.inputField === 'file') {

        // Update the last added section
        setFormSections(prevFormSections => {
            
            const lastSectionIndex = prevFormSections.length - 1;

            return prevFormSections.map((s, i) => {

                if (i === lastSectionIndex) {

                    return { ...s, question: 'Photo', disabled: true };
                    
                }

                return s;

            });
        });
    }

    // If the added section is for Address, update the question input value
    if (section.inputField === 'customFileFieldInput') {

        // Update the last added section
        setFormSections(prevFormSections => {
            
            const lastSectionIndex = prevFormSections.length - 1;

            return prevFormSections.map((s, i) => {

                if (i === lastSectionIndex) {

                    return { ...s, question: 'Custom', disabled: true };
                    
                }

                return s;

            });
        });
    }
}

/* -----------------------------------------
            Remove Form Section
-------------------------------------------- */

// Function to remove a form section
export const removeFormSection = (formSections, setFormSections, index) => {

    setFormSections(formSections.filter((_, i) => i !== index));

}


/*-----------------------------------------
                Clear Form Sections                 
------------------------------------------ */

export const clearForm = (setFormSections, setClearButtonClicked) => {

    setClearButtonClicked(true); // Trigger animation effect

    setTimeout(() => {

        setFormSections([]); // Resetting formSections to an empty array
        setClearButtonClicked(false); // Resetting clearButtonClicked state after clearing the form

    }, 100);

};



