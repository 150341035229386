import React from "react";

import { IoNewspaperOutline } from "react-icons/io5";

// CSS File 
import '../../../assets/css/Toasters.css';

const AddedChecklistToaster = () => {

    return (

        <div className="added-checklist-section-component-container">

            <div className="added-checklist-section-container">

                <div className="added-checklist-section-tick-container">

                    <IoNewspaperOutline className="added-checklist-section-tick" />
                    
                </div>

                <div className="added-checklist-section-text-container">

                    <div>
                        <p> Added Checklist field  </p>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default AddedChecklistToaster