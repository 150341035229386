import React from 'react';
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 

const MobileDeviceChecklistRender = ({ formName, formSections }) => {
    
    return (

        <div className="mobile-device-container">
            <div className="mobile-device">
                <div className="mobile-device-screen">
                    <div>
                        <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
                    </div>
                    <div>
                        <h2>{formName}</h2>
                    </div>
                </div>

                {/* Render form sections */}
                {formSections.map((section, index) => (
                    <div key={index}>
                        <div className="section-input-container">
                            {/* Ensure each section has a question property */}
                            <p>{section.question || section.name}</p>
                            {section.inputField === 'radio' && (
                                <div>
                                    {section.options.map((option, optionIndex) => (
                                        <div key={optionIndex}>
                                            <input
                                                type="radio"
                                                value={option}
                                                name={`radio-${index}`} // Use the same name for all radio buttons in this group
                                            />
                                            {option}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {section.inputField !== 'radio' && (
                                <input
                                    className="mobile-form-section-input"
                                    type={section.inputField}
                                    placeholder={
                                        section.inputField === 'phone' ? '+27 8 123 4567' : `Enter your ${section.name}`
                                    }
                                />
                            )}
                        </div>
                    </div>
                ))}
                <div>
                    <p>By clicking Submit you agree to that all information is true</p>
                </div>
                <div className="create-clear-form-button-containers">
                    <div>
                        <button type="button" className="create-form-button">
                            Submit Checklist
                        </button>
                    </div>
                    <div>
                        <button type="button" className="clear-form-button">
                            Clear Checklist
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileDeviceChecklistRender;
